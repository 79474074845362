import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import dashboardApi from '../../api/dashboardApi';
import distributorApi from '../../api/distributorApi';
import { uiChartNames, uiTabNames } from '../../config/constants';
import { setDashToDate, setDashFromDate, setDashDateString } from '../../reducers/DateReducer/DateActions';
import { linkNavigationBar, categoriesProductsNavigationBar, newLostLocationsNavigationBar } from '../../reducers/UIReducer/UIActions';
import { setDashboardHighlights, setDashboardNewLocations, setDashboardLostLocations, setDashboardTopCategories, setDashboardTopProducts, setTableDataLoading, setChartDataLoading, setDistributorBreakdownChartData, setCompetitiveInsightsChartData, setBepDistributorListLoading, setBEPDistributorListData, setHighlightsLoading } from '../../reducers/DashboardReducer/DashboardActions';
import LocalStorage from '../../sharedComponents/LocalStorage/LocalStorage';
import { setOppsFilters, setOpportunitiesData, setOpportunitiesTableData } from '../../reducers/OpportunitiesReducer/OpportunitiesActions';
import manufacturerApi from '../../api/manufacturerApi';
import { toast } from 'react-toastify';
import moment from 'moment';

const useDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setDashboardTab = () => {
    dispatch(linkNavigationBar.setTab(uiTabNames.dashboard));
  }

  const handleClickTopCategoriesTab = () => {
    dispatch(categoriesProductsNavigationBar.setTab(uiTabNames.topCategories));
  }

  const handleClickTopProductsTab = () => {
    dispatch(categoriesProductsNavigationBar.setTab(uiTabNames.topProducts));
  }

  const handleClickNewLocationsTab = () => {
    dispatch(newLostLocationsNavigationBar.setTab(uiTabNames.newLocations));
  }

  const handleClickLostLocationsTab = () => {
    dispatch(newLostLocationsNavigationBar.setTab(uiTabNames.lostLocations));
  }

  const handleClickViewCampaignManagement = () => {
    navigate('/campaignmanagement');
  }

  const filterCsvTable = () => {
    // Declare variables
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("csvSearchInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("distributorCsvTable");
    tr = table.getElementsByTagName("tr");
  
    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[0];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }
  
  return {
    setDashboardTab,
    handleClickTopCategoriesTab,
    handleClickTopProductsTab,
    handleClickNewLocationsTab,
    handleClickLostLocationsTab,
    handleClickViewCampaignManagement,
    filterCsvTable
  }
}

const useDates = () => {
  const dispatch = useDispatch();
  const fromDate = useSelector(({dates}) => dates.dashFromDate);
  const toDate = useSelector(({dates}) => dates.dashToDate);
  const topCategoriesData = useSelector(({dashboard}) => dashboard.topCategories);

  const onChangeDashboardDates = (start, end) => {
    dispatch(setDashFromDate(moment(start).format('YYYY-MM-DD')));
    dispatch(setDashToDate(moment(end).format('YYYY-MM-DD')));
    const dateString = moment(start).format('YYYY-MM-DD') + " - " + moment(end).format('YYYY-MM-DD');
    dispatch(setDashDateString(dateString));
  }

  const getHighlights = () => {
    const params = {
      'manufacturerPlatformId': LocalStorage.manufacturerData.get().platform_id,
      'startDate': fromDate,
      'endDate': toDate
    }
    dispatch(setHighlightsLoading(true));
    dashboardApi.highlights.post(params).then(({data}) => {
      dispatch(setHighlightsLoading(false));
      dispatch(setDashboardHighlights(data));
    }).catch(err => {
      console.log(err);
    });
  }

  const getNewLocations = () => {
    const params = {
      'manufacturerPlatformId': LocalStorage.manufacturerData.get().platform_id,
      'startDate': fromDate,
      'endDate': toDate
    }
    dispatch(setTableDataLoading(uiTabNames.newLocations, true));
    dashboardApi.newLocations.post(params).then(data => {
      dispatch(setDashboardNewLocations(data.data));
      dispatch(setTableDataLoading(uiTabNames.newLocations, false));
    }).catch(err => {
      console.log(err);
    });
  }

  const getLostLocations = () => {
    const params = {
      'manufacturerPlatformId': LocalStorage.manufacturerData.get().platform_id,
      'startDate': fromDate,
      'endDate': toDate
    }
    dispatch(setTableDataLoading(uiTabNames.lostLocations, true));
    dashboardApi.lostLocations.post(params).then(data => {
      dispatch(setDashboardLostLocations(data.data));
      dispatch(setTableDataLoading(uiTabNames.lostLocations, false));
    }).catch(err => {
      console.log(err);
    });
  }

  const getTopCategories = () => {
    const params = {
      'manufacturerPlatformId': LocalStorage.manufacturerData.get().platform_id,
      'startDate': fromDate,
      'endDate': toDate
    }
    dispatch(setTableDataLoading(uiTabNames.topCategories, true));
    dashboardApi.topCategories.post(params).then(data => {
      dispatch(setDashboardTopCategories(data.data));
      dispatch(setTableDataLoading(uiTabNames.topCategories, false));
    }).catch(err => {
      console.log(err);
    });
  }

  const getTopProducts = () => {
    const params = {
      'manufacturerPlatformId': LocalStorage.manufacturerData.get().platform_id,
      'startDate': fromDate,
      'endDate': toDate
    }
    dispatch(setTableDataLoading(uiTabNames.topProducts, true));
    dashboardApi.topProducts.post(params).then(data => {
      dispatch(setDashboardTopProducts(data.data));
      dispatch(setTableDataLoading(uiTabNames.topProducts, false));
    }).catch(err => {
      console.log(err);
    });
  }

  const getTotalOpportunitiesChartData = (filters) => {
    const mfId = LocalStorage.manufacturerData.get().platform_id;
    const dmId = LocalStorage.manufacturerData.get().data_manager_id;
    dispatch(setChartDataLoading(uiChartNames.totalOpportunitiesTable, true));
    var oppTypeFilters = "";
    var oppStatusFilters = "";
    if(filters?.length !== 0) {
        if(filters?.oppSourceStrings) {
          oppTypeFilters = filters.oppSourceStrings;
        }
        if(filters?.status) {
          oppStatusFilters = filters.status;
        }
    } else {
      dispatch(setOppsFilters([]));
    }
    const params = {
        'manufacturerPlatformId': mfId,
        'dataManagerId': dmId,
        'startDate': fromDate,
        'endDate': toDate,
        'complianceActivity': oppTypeFilters,
        "statuses": oppStatusFilters
    };
    manufacturerApi.agreements.post(params).then(({data}) => {
        dispatch(setOpportunitiesData(data.data.agreements));
        dispatch(setOpportunitiesTableData(data.data.tableData));
        dispatch(setChartDataLoading(uiChartNames.totalOpportunitiesTable, false));
    }).catch(err => {
        console.log(err);
        toast.error("Something went wrong loading your agreement data.", {autoClose: "5000"});
    });
  }

  const getDistributorBreakdownChartData = (filters) => {
    const mfId = LocalStorage.manufacturerData.get().platform_id;
    dispatch(setChartDataLoading(uiChartNames.distributorBreakdownTable, true));
    const params = {
        'manufacturerPlatformId': mfId,
        'startDate': fromDate,
        'endDate': toDate,
    };
    dashboardApi.charts.distributorBreakdown.post(params).then(({data}) => {
        dispatch(setDistributorBreakdownChartData(data));
        dispatch(setChartDataLoading(uiChartNames.distributorBreakdownTable, false));
    }).catch(err => {
        console.log(err);
        toast.error("Something went wrong loading your distributor data.", {autoClose: "5000"});
    });
  }

  const getCompetitiveInsightsChartData = (selectedCategories) => {
    const selectedFilters = selectedCategories?.map(categoryValue => categoryValue.value);
    const mfId = LocalStorage.manufacturerData.get().platform_id;
    dispatch(setChartDataLoading(uiChartNames.competitiveInsightsTable, true));
    if (topCategoriesData.length > 0) {
      const topCategories = topCategoriesData?.map(x => {return x.category});
      const params = {
        'manufacturerPlatformId': mfId,
        'startDate': fromDate,
        'endDate': toDate,
        'categories': (selectedFilters?.length > 0) ? selectedFilters : topCategories
      };
      dashboardApi.charts.competitiveInsights.post(params).then(({data}) => {
        dispatch(setChartDataLoading(uiChartNames.competitiveInsightsTable, false));
        dispatch(setCompetitiveInsightsChartData(data));
      }).catch(err => {
        console.log(err);
      });
    }
  }

  const getBEPDistributorListData = () => {
    dispatch(setBepDistributorListLoading(true));
    distributorApi.get().then(({data}) => {
      dispatch(setBepDistributorListLoading(false));
      dispatch(setBEPDistributorListData(data));
    }).catch(err => {
      console.log(err);
    });
  }

  const onClickDownloadCsv = (id) => {
    distributorApi.downloadCsv.get(id).then(({data}) => {
      const csv = data?.csvString;

      const removeHashtags = csv?.replace(/#/g, '');

      var hiddenElement = document.createElement('a');  
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(removeHashtags);  
      hiddenElement.target = '_blank';  
      
      //provide the name for the CSV file to be downloaded  
      hiddenElement.download = `BEP Distributor List ${id}.csv`;  
      hiddenElement.click();  
    });
  }

  const onChangeCsvDateFilter = (start, end) => {
    // Declare variables
    var table, tr, td, i, txtValue, startDate, endDate, parsedTxtValue;
    startDate = Date.parse(start.toDate());
    endDate = Date.parse(end.toDate());
    table = document.getElementById("distributorCsvTable");
    tr = table.getElementsByTagName("tr");

    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[1];
      if (td) {
        txtValue = td.textContent || td.innerText;
        txtValue = txtValue.split("-")[0] + "/1/" + txtValue.split("-")[1];
        parsedTxtValue = Date.parse(txtValue);
        if ((parsedTxtValue >= startDate) && (parsedTxtValue <= endDate)) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  };

  const onChangeHighlightsFilter = (filter) => {
    let beplBusiness = false;
    let directBusiness = false;
    switch (filter) {
      case ("Total Volume"): {
        beplBusiness = true;
        directBusiness = true;
        break;
      }
      case ("BEPL Business"): {
        beplBusiness = true;
        directBusiness = false;
        break;
      }
      case ("Direct Business"): {
        beplBusiness = false;
        directBusiness = true;
        break;
      }
      default: {
        beplBusiness = true;
        directBusiness = true;
        break;
      }
    }
    const params = {
      'manufacturerPlatformId': LocalStorage.manufacturerData.get().platform_id,
      'startDate': fromDate,
      'endDate': toDate,
      'showBeplBusiness': beplBusiness,
      'showDirectBusiness': directBusiness
    }
    dispatch(setHighlightsLoading(true));
    dashboardApi.highlights.post(params).then(({data}) => {
      dispatch(setHighlightsLoading(false));
      dispatch(setDashboardHighlights(data));
    }).catch(err => {
      console.log(err);
    });
  }

  const onApplySearch = () => {
    getHighlights();
    getNewLocations();
    getLostLocations();
    getTopCategories();
    getTopProducts();
    getTotalOpportunitiesChartData();
    getDistributorBreakdownChartData();
    getCompetitiveInsightsChartData();
  }

  return {
    setToDate: (d) => dispatch(setDashToDate(d)),
    setFromDate: (d) => dispatch(setDashFromDate(d)),
    toDate,
    fromDate,
    onApplySearch,
    getHighlights,
    getNewLocations,
    getLostLocations,
    getTopCategories,
    getTopProducts,
    getTotalOpportunitiesChartData,
    getDistributorBreakdownChartData,
    getCompetitiveInsightsChartData,
    getBEPDistributorListData,
    onClickDownloadCsv,
    onChangeHighlightsFilter,
    onChangeCsvDateFilter,
    onChangeDashboardDates
  };
}

// eslint-disable-next-line
export default { useDates, useDashboard };
