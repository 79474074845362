import React from 'react';
import { Container, Row, Col} from 'reactstrap';

const DashboardMobile = () => {
  return (
    <div className='bg-transparent' style={{minHeight:'86vh'}}>
    <Container fluid className='bg-transparent pt-3 summaryContainer'>
      <Row className='label-text text-size-14 d-flex align-items-center mx-auto '>
      </Row>
      <Row xs={1} md={1} lg={1} xl={1} className='label-text text-size-14 d-flex align-items-center card-row g-2'>
        <Col className="col-xxl-6 flex-grow-1">
          <div style={{textAlign:'center'}}>This view can only be viewed on the desktop or tablet website.</div>
        </Col>
      </Row>
    </Container>
    </div>
  );
}

export default DashboardMobile;