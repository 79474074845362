import React, { useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Spinner } from 'reactstrap';
import ReactSelect, { createFilter, components } from "react-select";
import { Chart as ChartJS, registerables } from 'chart.js';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { FaCircle } from '../../../../node_modules/react-icons/fa';
import { BsCaretDownFill } from 'react-icons/bs';
import Title from '../../../sharedComponents/Title/Title';
import useDashboard from '../useDashboard';
import formatters from '../../../config/formatters';
import { useState } from 'react';
import useDataSuite from '../../DataSuite/useDataSuite';
import EmptyDataMessage from '../../../sharedComponents/ErrorMessages/EmptyDataMessage';
import { selectStyles, Theme } from '../../../config/constants';

ChartJS.register(...registerables);

const CompetitiveInsightsChart = () => {
    const competitiveInsightsTableData = useSelector(({dashboard}) => dashboard.competitiveInsightsData);
    const allFilters = useSelector(({datasuite}) => datasuite.allFilters);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const isLoading = useSelector(({dashboard}) => dashboard.competitiveInsightsChartIsLoading);
    const topCategories = useSelector(({dashboard}) => dashboard.topCategories);
    const competitiveInsightsTableLabels = ['Your Business', 'Competitor', 'Competitor', 'Competitor', 'Competitor'];
    const tableColors = [Theme().colors.accent, 'rgba(49, 65, 117)', 'rgba(49, 65, 117)', 'rgba(49, 65, 117)', 'rgba(49, 65, 117)'];
    const [tableVolumeData, setTableVolumeData] = useState([]);
    const [tablePercentData, setTablePercentData] = useState([]);
    const { getCompetitiveInsightsChartData } = useDashboard.useDates();
    const { getFilters } = useDataSuite.useDataSuite();
    const { percentFormatter, usCurrencyFormatter } = formatters();

    // eslint-disable-next-line
    useEffect(() => {setupDataTables()}, [competitiveInsightsTableData, isLoading]);

    // eslint-disable-next-line
    useEffect(() => {getFilters()}, []);

    // eslint-disable-next-line
    useEffect(() => {getCompetitiveInsightsChartData(selectedFilters)}, [topCategories, selectedFilters]);

    const categoriesOptionsMap = allFilters?.categories?.map((data) => {
        return (
            {value: data, label: data}
        );
    });

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
            <BsCaretDownFill />
            </components.DropdownIndicator>
        );
    };
    
    const ValueContainer = ({ children, ...props }) => {
        let [values, input] = children;
        if (Array.isArray(values)) {
          values = `${values.length} Selected`;
        }
        return (
          <components.ValueContainer {...props}>
            {values}
            {input}
          </components.ValueContainer>
        );
      };

    const InputOption = ({
        getStyles,
        Icon,
        isDisabled,
        isFocused,
        isSelected,
        children,
        innerProps,
        ...rest
      }) => {
        const [isActive, setIsActive] = useState(false);
        const onMouseDown = () => setIsActive(true);
        const onMouseUp = () => setIsActive(false);
        const onMouseLeave = () => setIsActive(false);

        // styles
        let bg = "transparent";
        let textColor = "inherit";
        if (isDisabled) 
            { 
                bg = "rgb(230, 230, 230)";
                textColor= "rgb(77, 77, 77)";
            }
        if (isFocused && !isDisabled) bg = Theme().colors.accentLight;
        if (isActive) bg = Theme().colors.accent;

        const style = {
            alignItems: "center",
            backgroundColor: bg,
            color: textColor,
            display: "flex "
        };

        // prop assignment
        const props = {
            ...innerProps,
            onMouseDown,
            onMouseUp,
            onMouseLeave,
            style
        };
        return (
            <components.Option
              {...rest}
              isDisabled={isDisabled}
              isFocused={isFocused}
              isSelected={isSelected}
              getStyles={getStyles}
              innerProps={props}
            >
              <input className="me-2" type="checkbox" checked={isSelected} disabled={isDisabled} />
              {children}
            </components.Option>
        );
      };
    
    const setupDataTables = () => {
        if(!isLoading && competitiveInsightsTableData?.manufacturer && competitiveInsightsTableData?.competitors) {
            const yourBusinessData = competitiveInsightsTableData.manufacturer;
            const competitiveInsightsData = competitiveInsightsTableData.competitors;
            setTablePercentData([yourBusinessData[0]?.sales_percent, competitiveInsightsData[0]?.sales_percent, competitiveInsightsData[1]?.sales_percent, competitiveInsightsData[2]?.sales_percent, competitiveInsightsData[3]?.sales_percent])
            setTableVolumeData([yourBusinessData[0]?.total_volume, competitiveInsightsData[0]?.total_volume, competitiveInsightsData[1]?.total_volume, competitiveInsightsData[2]?.total_volume, competitiveInsightsData[3]?.total_volume]);
            var competitiveInsightsTableCanvas = document.getElementById("competitiveInsightsTableCanvas");
            var competitiveInsightsChart = ChartJS.getChart("competitiveInsightsTableCanvas");
            if(competitiveInsightsChart !== undefined) {
                competitiveInsightsChart.destroy();
            }
            // eslint-disable-next-line
            var competitiveInsightsTable = new ChartJS( competitiveInsightsTableCanvas ,{
                type: 'bar',
                data: {
                    labels: competitiveInsightsTableLabels, 
                    datasets: [
                        {
                            data: [yourBusinessData[0]?.sales_percent*100, competitiveInsightsData[0]?.sales_percent*100, competitiveInsightsData[1]?.sales_percent*100, competitiveInsightsData[2]?.sales_percent*100 , competitiveInsightsData[3]?.sales_percent*100],
                            backgroundColor: tableColors
                        }
                    ]
                },
                options: tableOptions
            });
        }
    };

    const tableOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display:false
                },
                ticks: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: false
                }
            }
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
              display: false,
          },
          tooltip: {
              enabled: true,
              xAlign: 'center',
              yAlign: 'center'
          },
        },
    };

    if(isLoading || (competitiveInsightsTableData.length <=0)) {
        return (
            <Col className='w-100 h-100'>
                <Row className='pb-1'>
                    <Col>
                        <Title title='Competitive Insights' />
                    </Col>
                    <Col className='mt-3'>
                        <ReactSelect 
                            captureMenuScroll={false}
                            isMulti
                            hideSelectedOptions={false}
                            classNamePrefix="custom-select "
                            filterOption={createFilter({ ignoreAccents: false })} 
                            components={{ ValueContainer, Option: InputOption, DropdownIndicator}}
                            name='category' 
                            styles={selectStyles} 
                            options = {categoriesOptionsMap}
                            onChange = {(value) => setSelectedFilters(value)}
                            value = {selectedFilters}
                            placeholder='Filter By Category'
                        />
                    </Col>
                </Row>
                <Row className="mx-auto" style={{height:'70%'}}>
                    <Col className="mx-auto my-auto" style={{height:"2.5%", width:"2.5%"}}>
                        {isLoading &&
                        <Spinner style={{marginLeft:'50%'}} color='beplBlue' animation="border" />
                        }
                        {!isLoading &&(competitiveInsightsTableData.length <=0) &&
                        <EmptyDataMessage />
                        }
                    </Col>
                </Row>
            </Col>
        );
    }
    else return (
        <Col>
            <Row className='pb-1'>
                <Col>
                    <Title title='Competitive Insights' />
                </Col>
                <Col className='mt-3'>
                    <ReactSelect 
                        captureMenuScroll={false}
                        isMulti
                        hideSelectedOptions={false}
                        classNamePrefix="custom-select "
                        filterOption={createFilter({ ignoreAccents: false })} 
                        components={{ ValueContainer, Option: InputOption, DropdownIndicator}}
                        name='category' 
                        styles={selectStyles} 
                        options = {categoriesOptionsMap}
                        onChange = {(value) => setSelectedFilters(value)}
                        value = {selectedFilters}
                        placeholder='Filter By Category'
                    />
                </Col>
            </Row>
            <Row className="w-100 mb-3 mx-0" style={{minHeight: '315px'}}>
                <Col className="col-12 col-xl-6 p-0">
                    <canvas id="competitiveInsightsTableCanvas"/>
                </Col>
                <Col className="col-12 col-xl-6 text-size-14 p-0" style={{marginTop: '4%'}}>
                <AutoSizer>
                        {({width, height}) => (
                            <Table className="table totalOpportunitiesTable border-grayLight" id="totalOpportunitiesTable"
                                width={width}
                                height={height}
                                headerHeight={34.5}
                                rowHeight={38}
                                rowCount={tableVolumeData.length}
                                rowGetter={({ index }) => tableVolumeData[index]}
                            >
                                <Column
                                label='Manufacturer'
                                dataKey='tableVolumneData'
                                width={width * 0.4}
                                cellRenderer={({cellData, rowIndex}) => (<span><FaCircle className='w-fc my-auto' style={{color: tableColors[rowIndex%5]}}/> {competitiveInsightsTableLabels[rowIndex%5]}</span>)}
                                />
                                <Column
                                width={width * 0.4}
                                label='Total Volumne'
                                dataKey='tableVolumneData'
                                cellRenderer={({cellData, rowIndex}) => (<span>{usCurrencyFormatter.format(tableVolumeData[rowIndex])}</span>)}
                                />
                                <Column
                                width={width * 0.2}
                                label='Sales %'
                                dataKey='tableVolumneData'
                                cellRenderer={({cellData, rowIndex}) => (<span>{percentFormatter.format(tablePercentData[rowIndex])}</span>)}
                                />
                            </Table>
                        )}
                    </AutoSizer>
                </Col>
            </Row>
        </Col>
    );
    
}

export default CompetitiveInsightsChart;