import React, { useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Spinner } from 'reactstrap';
import { Chart as ChartJS, registerables } from 'chart.js';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { FaCircle } from '../../../../node_modules/react-icons/fa';
import { IoMdInformationCircle } from 'react-icons/io';
import Title from '../../../sharedComponents/Title/Title';
import useDashboard from '../useDashboard';
import EmptyDataMessage from '../../../sharedComponents/ErrorMessages/EmptyDataMessage';
import { Theme } from '../../../config/constants';
import { Tooltip } from '@mui/material';

ChartJS.register(...registerables);

const DashboardTotalOpportunititesChart = () => {
    const opportunitiesTableData = useSelector(({opportunities}) => opportunities.opportunitiesTableData);
    const isLoading = useSelector(({dashboard}) => dashboard.totalOpportunitiesChartIsLoading);
    const totalOppsTableLabels = ['Active Opportunity', 'Won', 'Won Pending Validation', 'Identified'];
    const [ totalOppsData, setTotalOppsData ] = useState([]);
    const { getTotalOpportunitiesChartData } = useDashboard.useDates();
    const tableColors = [Theme().colors.accent, 'rgba(0, 157, 154)', 'rgba(250, 144, 22)', 'rgba(49, 65, 117)'];

    // eslint-disable-next-line
    useEffect(() => {setupDataTables()}, [opportunitiesTableData, isLoading]);

    // eslint-disable-next-line
    useEffect(() => {getTotalOpportunitiesChartData()}, []);
    
    const setupDataTables = () => {
        if(!isLoading && opportunitiesTableData?.data?.totalOpportunitiesTable && opportunitiesTableData?.data?.opportunitiesGeneratedTable) {
            setTotalOppsData([opportunitiesTableData?.data?.totalOpportunitiesTable[totalOppsTableLabels[0]],opportunitiesTableData?.data?.totalOpportunitiesTable[totalOppsTableLabels[1]],opportunitiesTableData?.data?.totalOpportunitiesTable[totalOppsTableLabels[2]],opportunitiesTableData?.data?.totalOpportunitiesTable[totalOppsTableLabels[3]]]);
            var totalOppsTableCanvas = document.getElementById("totalOppsTableCanvas");
            var totalOppsChart = ChartJS.getChart("totalOppsTableCanvas");
            if(totalOppsChart !== undefined) {
                totalOppsChart.destroy();
            }
            // eslint-disable-next-line
            var totalOppsTable = new ChartJS( totalOppsTableCanvas ,{
                type: 'bar',
                data: {
                    labels: totalOppsTableLabels, 
                    datasets: [
                        {
                            data: [opportunitiesTableData?.data?.totalOpportunitiesTable[totalOppsTableLabels[0]],opportunitiesTableData?.data?.totalOpportunitiesTable[totalOppsTableLabels[1]],opportunitiesTableData?.data?.totalOpportunitiesTable[totalOppsTableLabels[2]],opportunitiesTableData?.data?.totalOpportunitiesTable[totalOppsTableLabels[3]]],
                            backgroundColor: tableColors
                        }
                    ]
                },
                options: tableOptions
            });
        }
    };

    const tableOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display:false
                },
                ticks: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: false
                }
            }
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
              display: false,
          },
          tooltip: {
              enabled: true,
              xAlign: 'center',
              yAlign: 'center'
          },
        },
    };

    if(isLoading || (opportunitiesTableData.length <= 0)) {
        return (
            <Col className='w-100 h-100'>
                <div className='d-flex align-items-center px-0'>
                <Title title='Total Opportunities' />
                <Tooltip title='We show opportunities that have engagement. These may include opportunities driven by our internal team, email campaigns where the operator has engaged and other marketing campaigns. ' arrow>
                    <div className='pt-3 pb-2'>
                    <IoMdInformationCircle className="text-size-18 " style={{color: Theme().colors.accent}}/>
                    </div>
                </Tooltip>
                </div>
                <Row className="mx-auto"style={{height: '70%'}}>
                    <Col className="mx-auto my-auto" style={{height:"2.5%", width:"2.5%"}}>
                        {isLoading &&
                        <Spinner style={{marginLeft:'50%'}} color='beplBlue' animation="border" />
                        }
                        {!isLoading && (opportunitiesTableData.length <= 0) &&
                        <EmptyDataMessage />
                        }
                    </Col>
                </Row>
            </Col>
        );
    }
    else return (
        <Col>
            <div className='d-flex align-items-center px-0'>
                <Title title='Total Opportunities' />
                <Tooltip title='We show opportunities that have engagement. These may include opportunities driven by our internal team, email campaigns where the operator has engaged and other marketing campaigns. ' arrow>
                    <div className='pt-3 pb-2'>
                    <IoMdInformationCircle className="text-size-18 " style={{color: Theme().colors.accent}}/>
                    </div>
                </Tooltip>
            </div>
            <Row className="w-100 mb-3 mx-0" style={{minHeight: '315px'}}>
                <Col className="col-12 col-xl-6 p-0">
                    <canvas id="totalOppsTableCanvas"/>
                </Col>
                <Col className="col-12 col-xl-6 text-size-14 p-0" style={{marginTop: '4%'}}>
                    <AutoSizer>
                        {({width, height}) => (
                            <Table className="table totalOpportunitiesTable border-grayLight" id="totalOpportunitiesTable"
                                width={width}
                                height={height}
                                headerHeight={34.5}
                                rowHeight={38}
                                rowCount={totalOppsData.length}
                                rowGetter={({ index }) => totalOppsData[index]}
                            >
                                <Column
                                label='Opportunity Status'
                                dataKey='totalOppsData'
                                width={width * 0.7}
                                cellRenderer={({cellData, rowIndex}) => (<span><FaCircle className='w-fc my-auto' style={{color: tableColors[rowIndex%4]}}/> {totalOppsTableLabels[rowIndex%4]}</span>)}
                                />
                                <Column
                                width={width * 0.3}
                                label='Opportunities'
                                dataKey='totalOppsData'
                                cellRenderer={({cellData, rowIndex}) => (<span>{totalOppsData[rowIndex]}</span>)}
                                />
                            </Table>
                        )}
                    </AutoSizer>
                </Col>
            </Row>
        </Col>
    );
    
}

export default DashboardTotalOpportunititesChart;