import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import useTopBar from '../TopBar/useTopBar';
import * as $ from 'jquery';
import { Theme } from '../../config/constants';

const FooterNav = () => {
  const { links, goToLinks } = useTopBar.useLinks();
  const [h, setH] = useState(0);

  useEffect(() => {
    const h = $('#footer-container').outerHeight();
    setH(h);
  }, [setH]);

  const linksMap = links.map((l, i) => (
    <Col onClick={() =>{ goToLinks(l);}} className={'text-size-10 text-center d-flex flex-column pt-3 pb-1'+(l.isActive ? ' bg-' + Theme().colors.accentName + ' text-white' : 'text-black')} key={`footer-nav-${i}`}>
      <Row>
        <Col style={{filter: l.isActive ? 'invert(1)' : ''}}>
          <l.icon />
        </Col>
        <span className='position-absolute right-0 top-0'>
          <l.iconComingSoon />
        </span>
      </Row>
      <Row className='no-gutters flex-grow-1 justify-content-center align-items-center'>
        <Col style={{textAlign:'center'}}>{l.title}</Col>
      </Row>
    </Col>
  ))

  return (
    <div id='footer-relative' style={{height: `${h}px`, zIndex:'5'}}>
      <Container id='footer-container' fluid className='bg-white p-0 position-fixed bottom-0'>
        <Row className='no-gutters'>
          {linksMap}
        </Row>
      </Container>
    </div>
  );
};

export default FooterNav;