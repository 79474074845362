import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './Dashboard/DashboardMobile';
import Logout from './Logout/Logout';
import Settings from './Settings/Settings';
import Help from './Help/Help';
import MarketingCampaigns from './MarketingCampaigns/MarketingCampaignsMobile';
import MarketingCampaignsDetails from './MarketingCampaigns/MarketingCampaignDetails/MarketingCampaignsDetails';

const AppMainMobileRouter = () => {

  return (
    <Routes>
      <Route exact path='/dashboard' element={<Dashboard />} />
      <Route exact path='/logout' element={<Logout />} />
      <Route exact path='/settings' element={<Settings />} />
      <Route exact path='/help' element={<Help />} />
      <Route exact path='/campaignmanagement' element={<MarketingCampaigns />} />
      <Route path='/campaignmanagement/:id' element={<MarketingCampaignsDetails />} />
      <Route path="*" element={<Navigate to='/dashboard' replace={true} />} />
    </Routes>
  );
};

export default AppMainMobileRouter;