import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AppMain  from '../AppMain/AppMain';
import SignUp from '../views/SignUp/SignUp';
import Login from '../views/Login/Login';
import Logout from '../AppMain/Logout/Logout';
import Activate from '../views/Activate/Activate';
import ForgotPassword from '../views/ForgotPassword/ForgotPassword';
import CreatePassword from '../views/CreatePassword/CreatePassword';
import ChangePasswordToken from '../views/ChangePasswordToken/ChangePasswordToken';
import ResendActivationEmail from '../views/ResendActivationEmail/ResendActivationEmail';
import AdminLogin from '../views/AdminLogin/AdminLogin';
import Maintenance from '../views/Maintenance/Maintenance';

const Router = () => {
  const isLogin = useSelector(({user}) => user.isLogin);
  const isAdmin = useSelector(({user}) => user.isAdmin);
  const isMaintenance = false;
  if (isMaintenance) {
    return (
      <Routes>
          <Route exact path='/maintenance' element={<Maintenance />} />
          <Route path="*" element={<Navigate to='/maintenance' replace={true} />}/>
      </Routes>
    )
  }
  if(isLogin) {
    if(isAdmin) {
      return (
        <Routes>
          <Route exact path='/logout' element={<Logout />} />
          <Route exact path='/admin-login' element={<AdminLogin />} />
          <Route exact path='/dashboard' element={<AppMain />} />
          <Route path="*" element={<Navigate to='/admin-login' replace={true} />} />
        </Routes>
      );
    }
    return (
        <Routes>
        <Route exact path='/logout' element={<Logout />} />
        <Route path='*' element={<AppMain />} />
        </Routes>
    )
  }
  return (
      <Routes>
          <Route exact path='/signup' element={<SignUp />} />
          <Route exact path='/activate/*' element={<Activate />} />
          <Route exact path='/forgot-password' element={<ForgotPassword />} />
          <Route exact path='/resend-activation-email' element={<ResendActivationEmail />} />
          <Route exact path='/create-password/*' element={<CreatePassword />} />
          <Route exact path='/change-password/*' element={<ChangePasswordToken />} />
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/logout' element={<Logout />} />
          <Route path="*" element={<Navigate to='/login' replace={true} />} />
      </Routes>
  );
};

export default Router;