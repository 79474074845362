import React, { useState } from 'react';
import { Row, Col, Input, Button, FormText, Form, FormGroup, Label, FormFeedback } from 'reactstrap';
import useLoginForm from './useLoginForm';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom/dist';
import { If } from 'react-if';
import authApi from '../../api/authApi';
import AlertModal from '../../sharedComponents/AlertModal/AlertModal';
import { showModal } from '../../reducers/ModalsReducer/ModalsActions';
import useLogin from '../../sharedComponents/useLogin/useLogin';
import { spinner } from '../../reducers/UIReducer/UIActions';
import { FullStory } from '@fullstory/browser';
import { v4 as uuidv4 } from 'uuid';
import { withMsal } from '@azure/msal-react';
import useMsal from '../../sharedComponents/useMsal/useMsal';
import { BsMicrosoft } from 'react-icons/bs';
import { Theme } from '../../config/constants';

const LoginForm = (props) => {
  const { email, password, validate } = useLoginForm();
  const [apiError, setApiError] = useState('');
  const { login } = useLogin();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginPopup } = useMsal();
  const formFieldClassName = localStorage.getItem('branding') === "ARROWSTREAM" ? 'as-form-field' : 'form-field';

  const onLogin = (e) => {
    e.preventDefault();
    setApiError('');
    const errors = validate();
    if (!errors) {
      const params = {
        email: email.value,
        password: password.value
      };
      dispatch(spinner.add());
      authApi.login.post(params).then(({data}) => {
        dispatch(spinner.subtract());
        login(data);
        const uuid = uuidv4();
        FullStory.identify(uuid, {displayName: data.user.first_name + ' ' + data.user.last_name, email: data.user.email});
      }).catch(err => {
        console.log(err);
        dispatch(spinner.subtract());
        let msg = `Something went wrong`;
        if (err) {
          if (err.data && err.data.message) {
            msg = err.data.message;
          }
        }
        const modalProps = {
          title: 'Log In Error',
          message: 'Email or password does not match or account is not active',
          buttonColor: Theme().colors.accentName,
          buttonText: 'Close',

          buttonClick: () => {
            navigate('/login');
          }                        
        };                
        dispatch(showModal(AlertModal, modalProps));
        setApiError(msg);
      });
    }
  };

  const goToSignUp = () => {
    navigate('/signup');
  };

  const goToResendActivationEmail = () => {
    navigate('/resend-activation-email');
  };

  const goToForgotPassword = () => {
    navigate('/forgot-password');
  };

  return (
    <Form className="text-size-14" onSubmit={onLogin}>
      <If condition={props.activateMsg}>
        <FormGroup>
          <FormText color='primary'>Account Already being Activated. Please Login.</FormText>
        </FormGroup>
      </If>
      <FormGroup className='field' style={{minHeight: '87px'}}>
        <Input {...email} placeholder='Email' type='text' className={formFieldClassName} />
        <Label className='label-text-header form-label login-label' for='email'>Email</Label>
        <FormFeedback>{email.message}</FormFeedback>
      </FormGroup>
      <FormGroup className='field' style={{minHeight: '87px'}}>
        <Input {...password} type='password' className={formFieldClassName} placeholder='Password'/>
        <Label className='label-text-header form-label login-label' for='password'>Password</Label>
        <FormFeedback>{password.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Button type='submit' block color={Theme().colors.accentName} className='mx-auto col-12 mb-3 button-label'>Log In</Button>
      </FormGroup>
      <FormGroup>
        <FormText color='red'>{apiError}</FormText>
      </FormGroup>
      <FormGroup className='m-b-5'>
        <Col onClick={goToForgotPassword} className='form-label text-center clickable' style={{color: Theme().colors.accent}}>Forgot Password?</Col>
        <Col onClick={goToResendActivationEmail} className='form-label text-center clickable' style={{color: Theme().colors.accent}}>Resend Activation Email</Col>
      </FormGroup>
      <Row className='my-3'>
        <Col className='my-auto' style={{height: '2px', background: 'darkGray'}}></Col>
        <Col className='col-2 form-label text-grayDark text-size-16 text-center p-1 m-0'>OR</Col>
        <Col className='my-auto' style={{height: '2px', background: 'darkGray'}}></Col>
      </Row>
      <FormGroup className='mt-2 mb-4'>
        <Button block color='blue' onClick={() => loginPopup(props.msalContext.instance, false)} className='mx-auto col-12 mt-2 mb-3 button-label'><BsMicrosoft className='mx-2 my-auto'/>Log In with Microsoft</Button>
        <Col className='form-label text-center text-grayDark my-2'>Don't have an account? <span onClick={goToSignUp} className='form-label text-center clickable' style={{color: Theme().colors.accent}}>Sign Up</span></Col>
      </FormGroup>
    </Form>
  );
};

export default withMsal(LoginForm);