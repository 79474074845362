import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import AppMainRouter from './AppMainRouter';
import AppMainMobileRouter from './AppMainMobileRouter';
import Header from '../sharedComponents/Header/Header';
import { If, Then, Else } from 'react-if';
import { isMobile, isMobileOnly } from 'react-device-detect';
import HeaderLogo from '../sharedComponents/Header/HeaderLogo';
import HeaderLogoLoggedIn from '../sharedComponents/Header/HeaderLogoLoggedIn';
import FooterNav from '../sharedComponents/FooterNav/FooterNav';
import BlankContainer from '../sharedComponents/BlankContainer/BlankContainer';
import LocalStorage from '../sharedComponents/LocalStorage/LocalStorage';
import ProfileSlideDrawer from '../sharedComponents/SlideDrawer/ProfileSlideDrawer';
import HelpSlideDrawer from '../sharedComponents/SlideDrawer/HelpSlideDrawer';
import NotificationsSlideDrawer from '../sharedComponents/SlideDrawer/NotificationsSlideDrawer';
import { AppShell } from '@mantine/core';

const AppMain = () => {
  const ready = true;
  const isLogin = useSelector(({user}) => user.isLogin);
  const [connectionMade, setConnectionMade] = useState(false);
  const headerLogo = localStorage.getItem('branding') === "ARROWSTREAM" ? './arrowstreamLogoWhite.png' : process.env.REACT_APP_COMPANY_LOGO;
  const headerLogoColor = localStorage.getItem('branding') === "ARROWSTREAM" ? './arrowstreamLogo.png' : process.env.REACT_APP_COMPANY_LOGO_COLOR;

  const mfrData = LocalStorage.manufacturerData.get();

  // Incomplete login, like a session refreshed at admin-login
  if (!mfrData) {
    localStorage.clear();
    document.location.reload();
  }

  const mfrId = LocalStorage.manufacturerData.get().platform_id;
  const authToken = LocalStorage.authKey.get();
  const isAdmin = LocalStorage.userData.get().user.internalAdmin;
  const userName = LocalStorage.userData.get().user.first_name + ' ' + LocalStorage.userData.get().user.last_name;
  const userEmail = LocalStorage.userData.get().user.email;

  const handleChatBotConnection = (event) => {
    if (event.data === 'chatbotHandshake') {
      setConnectionMade(true);
      event.source.postMessage('chatbotConnectionMade', event.origin);
    }
    if (event.data === 'chatbotReadyForData') {
      event.source.postMessage({authToken: authToken, platformId: mfrId, sessionId: authToken, internalUser: isAdmin, platform: 'manufacturer', user_name: userName, user_email: userEmail, forceMobileView: true}, event.origin);
    }
  }

  useEffect(() => {
    if (!connectionMade) {
      window.addEventListener("message", handleChatBotConnection);
      setConnectionMade(true);
      console.log("🚀 ~ Chatbot event listener attached");
    }

    return () => window.removeEventListener("message", handleChatBotConnection);
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <If condition={isMobile}>
        <Then>
        <If condition={isLogin}>
          <Then>
            <HeaderLogoLoggedIn logo={headerLogoColor} />
          </Then>
          <Else>
            <HeaderLogo logo={headerLogo} />
          </Else>
        </If>
        </Then>
        <Else>
          <ProfileSlideDrawer />
          <HelpSlideDrawer />
          <NotificationsSlideDrawer />
        </Else>
      </If>
      <If condition={ready}>
        <If condition={isMobileOnly}>
          <Then>
            <AppMainMobileRouter />
          </Then>
          <Else>
            <AppShell
              navbar={{
                width: 250,
                breakpoint: 'sm'
              }}
              padding="md"
            >
              <AppShell.Navbar>
                <Header />
              </AppShell.Navbar>

              <AppShell.Main style={{height: '100vh'}}>
                <AppMainRouter />
              </AppShell.Main>
            </AppShell>
          </Else>
        </If>
        <Then>
          <AppShell
            navbar={{
              width: 250,
              breakpoint: 'sm'
            }}
            padding="md"
          >
            <AppShell.Navbar>
              <Header />
            </AppShell.Navbar>

            <AppShell.Main style={{height: '100vh'}}>
              <AppMainRouter />
            </AppShell.Main>
          </AppShell>
        </Then>
        <Else>
          <BlankContainer />
        </Else>
      </If>
      <If condition={isMobile}>
        <Then>
          <FooterNav />
        </Then>
      </If>
      <div id='chatbotContainer' style={{position: 'fixed', right: '10px', bottom: '0px', zIndex: '5', visibility: 'hidden'}}>
        <iframe src={process.env.REACT_APP_CHATBOT_FRONTEND_URL} height="700" width="600" title="Chatbot" id='chatbotIframe' style={{background: 'white', boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)', border: 'white solid 4px'}}></iframe>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Fragment>
  );
};

export default AppMain;