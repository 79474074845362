import React, { useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Spinner } from 'reactstrap';
import { Chart as ChartJS, registerables } from 'chart.js';
import { AutoSizer, Table, Column } from 'react-virtualized';
import { FaCircle } from '../../../../node_modules/react-icons/fa';
import Title from '../../../sharedComponents/Title/Title';
import formatters from '../../../config/formatters';
import EmptyDataMessage from '../../../sharedComponents/ErrorMessages/EmptyDataMessage';
import { Theme } from '../../../config/constants';

ChartJS.register(...registerables);

const DashboardDistributorBreakdownChart = () => {
    const distributorBreakdownTableData = useSelector(({dashboard}) => dashboard.distributorBreakdownData);
    const isLoading = useSelector(({dashboard}) => dashboard.totalOpportunitiesChartIsLoading);
    const distributorBreakdownTableLabels = ['Manufacturer Brand', 'Customer Brand', 'Distributor Brand'];
    const { percentFormatter, usCurrencyFormatter } = formatters();
    const tableColors = [Theme().colors.accent, 'rgb(73, 164, 218)', 'rgb(49, 65, 117)'];

    // eslint-disable-next-line
    useEffect(() => {setupDataTables()}, [distributorBreakdownTableData, isLoading]);
    
    const setupDataTables = () => {
        if(!isLoading && (distributorBreakdownTableData.length > 0)) {
            const distributorBreakdownData = distributorBreakdownTableData;
            var distributorTableCanvas = document.getElementById("distributorTableCanvas");
            var distributorChart = ChartJS.getChart("distributorTableCanvas");
            if(distributorChart !== undefined) {
                distributorChart.destroy();
            }
            // eslint-disable-next-line
            var distributorTable = new ChartJS( distributorTableCanvas ,{
                type: 'doughnut',
                data: {
                    labels: distributorBreakdownTableLabels, 
                    datasets: [
                        {
                            data: [distributorBreakdownData[0]?.total_volume, distributorBreakdownData[1]?.total_volume, distributorBreakdownData[2]?.total_volume],
                            backgroundColor: tableColors
                        }
                    ]
                },
                options: tableOptions
            });
        }
    };

    const tableOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                display: false
            },
            y: {
                display: false
            }
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
              display: false,
          },
          tooltip: {
              enabled: true,
              xAlign: 'center',
              yAlign: 'center'
          },
        },
    };

    if(isLoading || (distributorBreakdownTableData.length <= 0)) {
        return (
            <Col className='w-100 h-100'>
                <Title title='Brand Breakdown'/>
                <Row className="mx-auto" style={{height: '70%'}}>
                    <Col className="mx-auto my-auto" style={{height:"2.5%", width:"2.5%"}}>
                        {isLoading &&
                        <Spinner style={{marginLeft:'50%'}} color='beplBlue' animation="border" />
                        }
                        {!isLoading &&
                        <EmptyDataMessage />
                        }
                    </Col>
                </Row>
            </Col>
        );
    }
    else return (
        <Col>
            <Title title='Brand Breakdown' />
            <Row className="mb-3 mx-0" style={{minHeight: '200px'}}>
                <Col className="w-50 p-0">
                    <canvas id="distributorTableCanvas"/>
                </Col>
                <Col className="w-50 text-size-14 p-0" style={{marginTop: '6%'}}>
                    <AutoSizer>
                        {({width, height}) => (
                            <Table className="table brandBreakdownTable border-grayLight" id="brandBreakdownTable"
                                width={width}
                                height={height}
                                headerHeight={34.5}
                                rowHeight={38}
                                rowCount={distributorBreakdownTableData?.length}
                                rowGetter={({ index }) => distributorBreakdownTableData[index]}
                            >
                                <Column
                                label='Brand'
                                dataKey='item_brand_owner'
                                width={width * 0.425}
                                cellRenderer={({cellData, rowIndex}) => (<span><FaCircle className='w-fc my-auto' style={{color: tableColors[rowIndex%3]}}/> {cellData} Brand</span>)}
                                />
                                <Column
                                width={width * 0.4}
                                label='Total Volume'
                                dataKey='total_volume'
                                cellRenderer={({cellData}) => usCurrencyFormatter.format(cellData)}
                                />
                                <Column
                                width={width * 0.175}
                                label='Sales %'
                                dataKey='sales_percent'
                                cellRenderer={({cellData}) => percentFormatter.format(cellData)}
                                />
                            </Table>
                        )}
                    </AutoSizer>
                </Col>
            </Row>
        </Col>
    );
    
}

export default DashboardDistributorBreakdownChart;