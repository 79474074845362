import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Spinner, Button } from "reactstrap";
import formatters from "../../../config/formatters";
//import useDashboard from "../useDashboard";
import ReactSelect, { components } from 'react-select';
import useDashboardTables from "./useDashboardTables";
import { useLocation } from "react-router-dom";
import EmptyDataMessage from "../../../sharedComponents/ErrorMessages/EmptyDataMessage";
import { selectStyles, Theme } from "../../../config/constants";
import { BsCaretDownFill } from "react-icons/bs";
import { Tooltip } from "@mui/material";
import { IoMdInformationCircle } from "react-icons/io";

const NewLocationsTable = () => {
    const location = useLocation();
    const isLoading = useSelector(({dashboard}) => dashboard.newLocationsIsLoading);
    const newLocationsData = useSelector(({dashboard}) => dashboard.newLocations);
    const { onSelectChange, onClickSeeMore } = useDashboardTables.useNewLocationsTable();
    const { usCurrencyFormatter } = formatters();
    const pathname = location.pathname;
    const isDashboard = (pathname === '/dashboard');
    const newLocationsOptions = [
        {value: 'Total Volume', label: 'Sort By: Total Volume ($)'},
        {value: 'Locations', label: 'Sort By: Locations'},
        {value: 'Cases', label: 'Sort By: Cases'}
    ];

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
            <BsCaretDownFill />
            </components.DropdownIndicator>
        );
    };

    const InputOption = ({
        getStyles,
        Icon,
        isDisabled,
        isFocused,
        isSelected,
        children,
        innerProps,
        ...rest
      }) => {
        const [isActive, setIsActive] = useState(false);
        const onMouseDown = () => setIsActive(true);
        const onMouseUp = () => setIsActive(false);
        const onMouseLeave = () => setIsActive(false);

        // styles
        let bg = "transparent";
        let textColor = "inherit";
        if (isDisabled) 
            { 
                bg = "rgb(230, 230, 230)";
                textColor= "rgb(77, 77, 77)";
            }
        if (isFocused && !isDisabled) bg = Theme().colors.accentLight;
        if (isActive) bg = Theme().colors.accent;

        const style = {
            alignItems: "center",
            backgroundColor: bg,
            color: textColor,
            display: "flex "
        };

        // prop assignment
        const props = {
            ...innerProps,
            onMouseDown,
            onMouseUp,
            onMouseLeave,
            style
        };
        return (
            <components.Option
              {...rest}
              isDisabled={isDisabled}
              isFocused={isFocused}
              isSelected={isSelected}
              getStyles={getStyles}
              innerProps={props}
            >
              {children}
            </components.Option>
        );
      };

    var newLocationsMap = newLocationsData?.map((data, idx) =>{
        return (
            <tr key={`${idx}`}>
                <td>{data?.location_name}</td>
                <td>{usCurrencyFormatter.format(data?.total_volume)}</td>
                <td>{data?.total_cases}</td>
            </tr>
        )
    });

    if (isDashboard) {
        newLocationsMap = newLocationsData?.slice(0, 5).map((data, idx) =>{
            return (
                <tr key={`${idx}`}>
                    <td>{data?.location_name}</td>
                    <td>{usCurrencyFormatter.format(data?.total_volume)}</td>
                    <td>{data?.total_cases}</td>
                </tr>
            )
        });
    }

    const slideDrawerContents = (
        <div className='m-3' style={{height: '92vh', overflowY: 'auto'}}>
            <table className="table topCategoriesTable border-grayLight" id="topCategoriesTable">
                <thead>
                <tr>
                    <th scope="col" >LOCATIONS</th>
                    <th scope="col" >TOTAL VOLUME ($)</th>
                    <th scope="col" >CASES</th>
                </tr>
                </thead>
                <tbody>
                    {newLocationsMap}
                </tbody>
            </table>
        </div>
    );

    if (isLoading || newLocationsMap.length <= 0) {
        return (
            <Col style={{height: '100%'}}>
                <Row className="pb-2">
                    <Col className="col-5">
                        <ReactSelect 
                            styles={selectStyles}
                            components={{ DropdownIndicator, Option: InputOption}}
                            options = {newLocationsOptions}
                            defaultValue = {newLocationsOptions[0]}
                        />
                    </Col>
                    <Col className='d-flex align-items-center px-0 my-auto'>
                        <Tooltip title='New locations are classified as locations that our data shows have not purchased from you before.' arrow>
                            <div className='py-auto'>
                            <IoMdInformationCircle className="text-size-18 " style={{color: Theme().colors.accent}}/>
                            </div>
                        </Tooltip>
                    </Col>
                    <Col style={{display: (isDashboard ? 'none' : '')}}>
                        <Button 
                            block 
                            outline={true}
                            style={{float: 'right', padding: '6px', fontSize: '14px'}} 
                            color={Theme().colors.accentName}>See More
                        </Button>
                    </Col>
                </Row>
                <Row className="h-100">
                    <div style={{height: '100%'}}>
                        {isLoading && 
                        <Col className="mx-auto my-auto h-100 w-100" style={{height:"2.5%", width:"2.5%"}}>
                            <Spinner color='beplBlue' animation="border" />
                        </Col>
                        }
                        {!isLoading &&
                        <EmptyDataMessage />
                        }
                    </div>  
                </Row>
            </Col>
            
        );
    }
    return (
        <Col style={{height: '100%'}}>
            <Row className="pb-2">
                <Col className="col-5">
                    <ReactSelect 
                        styles={selectStyles}
                        components={{ DropdownIndicator, Option: InputOption}}
                        options = {newLocationsOptions}
                        defaultValue = {newLocationsOptions[0]}
                        onChange = {(e) => onSelectChange(e.value, newLocationsData)}
                    />
                </Col>
                <Col className='d-flex align-items-center px-0'>
                    <Tooltip title='New locations are classified as locations that our data shows have not purchased from you before.' arrow>
                        <div className='py-auto'>
                        <IoMdInformationCircle className="text-size-18 " style={{color: Theme().colors.accent}}/>
                        </div>
                    </Tooltip>
                </Col>
                <Col style={{display: (isDashboard ? 'none' : '')}}>
                    <Button 
                        block 
                        outline={true}
                        style={{float: 'right', padding: '6px', fontSize: '14px'}} 
                        color={Theme().colors.accentName}
                        onClick={() => onClickSeeMore('All New Locations', slideDrawerContents)}>See More
                    </Button>
                </Col>
            </Row>
            <Row style={{overflowY: 'auto', height: '83%'}}>
                <div>
                    <table className="table topCategoriesTable border-grayLight" id="topCategoriesTable">
                        <thead>
                        <tr>
                            <th scope="col" >LOCATIONS</th>
                            <th scope="col" >TOTAL VOLUME ($)</th>
                            <th scope="col" >CASES</th>
                        </tr>
                        </thead>
                        <tbody>
                            {newLocationsMap}
                        </tbody>
                    </table>
                </div>
            </Row>
        </Col>
        
    ); 
}

export default NewLocationsTable;