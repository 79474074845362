import React, { useState } from "react";
import { Col, Row, Spinner } from "reactstrap";
import { IoMdInformationCircle } from "react-icons/io";
import ReactSelect, { components } from "react-select";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import formatters from "../../../config/formatters";
import useDashboard from "../useDashboard";
import { BsCaretDownFill } from "react-icons/bs";
import { selectStyles, Theme } from "../../../config/constants";

const HighlightsCard = () => {
    const highlightsData = useSelector(({dashboard}) => dashboard.highlights);
    const isLoading = useSelector(({dashboard}) => dashboard.highlightsIsLoading);
    const { usCurrencyFormatter, numberFormatter } = formatters();
    const { onChangeHighlightsFilter } = useDashboard.useDates();
    const highlightsOptions = [
        {value: 'Total Volume', label: 'Show: Total Volume'},
        {value: 'BEPL Business', label: 'Show: BEPL Business'},
        {value: 'Direct Business', label: 'Show: Direct Business'}
    ];

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
            <BsCaretDownFill />
            </components.DropdownIndicator>
        );
    };

    const InputOption = ({
        getStyles,
        Icon,
        isDisabled,
        isFocused,
        isSelected,
        children,
        innerProps,
        ...rest
      }) => {
        const [isActive, setIsActive] = useState(false);
        const onMouseDown = () => setIsActive(true);
        const onMouseUp = () => setIsActive(false);
        const onMouseLeave = () => setIsActive(false);

        // styles
        let bg = "transparent";
        let textColor = "inherit";
        if (isDisabled) 
            { 
                bg = "rgb(230, 230, 230)";
                textColor= "rgb(77, 77, 77)";
            }
        if (isFocused && !isDisabled) bg = Theme().colors.accentLight;
        if (isActive) bg = Theme().colors.accent;

        const style = {
            alignItems: "center",
            backgroundColor: bg,
            color: textColor,
            display: "flex "
        };

        // prop assignment
        const props = {
            ...innerProps,
            onMouseDown,
            onMouseUp,
            onMouseLeave,
            style
        };
        return (
            <components.Option
              {...rest}
              isDisabled={isDisabled}
              isFocused={isFocused}
              isSelected={isSelected}
              getStyles={getStyles}
              innerProps={props}
            >
              {children}
            </components.Option>
        );
      };

    return (
        <Col className="py-2">
            <Row className="my-2" style={{width: '240px'}}>
                <Col>
                    <ReactSelect 
                        styles={selectStyles}
                        components={{DropdownIndicator, Option: InputOption}}
                        defaultValue={highlightsOptions[0]}
                        options = {highlightsOptions}
                        onChange = {(e) => onChangeHighlightsFilter(e.value)}
                    >
                    </ReactSelect>
                </Col>
            </Row>
            <Row style={{height: '40%'}}>
                <Col className='col-4' style={{borderRight: '1px lightgray solid'}}>
                    <Row>
                        <Col>
                            <div className="main-header text-gray text-size-16">Total Volume ($)</div>
                        </Col>
                        <Col className="w-fc">
                            <Tooltip placement='top' title='This section will show you your total volume, direct volume or BEPL volume within the timeframe you select from the date filters' arrow>
                                <div>
                                    <IoMdInformationCircle className="text-size-18" color={Theme().colors.accent} />
                                </div>
                            </Tooltip>
                        </Col>
                    </Row>
                    {isLoading &&
                    <Row className="my-2 mx-auto w-fc">
                        <Spinner color='beplBlue' animation="border"/>
                    </Row>
                    }
                    {!isLoading && 
                    <Row className="my-2">
                        <div className="main-header text-size-36">{highlightsData.length <= 0 ? 'N/A' : usCurrencyFormatter.format(highlightsData.total_volume)}</div>
                    </Row>
                    }
                </Col>
                <Col className='col-4' style={{borderRight: '1px lightgray solid'}}>
                    <Row>
                        <Col>
                            <div className="main-header text-gray text-size-16">Locations</div>
                        </Col>
                        <Col className="w-fc">
                            <Tooltip placement='top' title='Your total locations (ie, individual store locations) within the selected date range' arrow>
                                <div>
                                    <IoMdInformationCircle className="text-size-18" style={{color: Theme().colors.accent}} />
                                </div>
                            </Tooltip>
                        </Col>
                    </Row>
                    {isLoading && 
                    <Row className="my-2 mx-auto w-fc">
                        <Spinner color='beplBlue' animation="border"/>
                    </Row>
                    }
                    {!isLoading && 
                    <Row className="my-2">
                        <div className="main-header text-size-36">{highlightsData.length <= 0 ? 'N/A' : numberFormatter.format(highlightsData.total_locations)}</div>
                    </Row>
                    }
                </Col>
                <Col className='col-4'>
                    <Row>
                        <Col>
                            <div className="main-header text-gray text-size-16">Cases</div>
                        </Col>
                        <Col className="w-fc">
                            <Tooltip placement='top' title='Your total cases across all categories in the selected date range' arrow>
                                <div>
                                    <IoMdInformationCircle className="text-size-18" style={{color: Theme().colors.accent}}/>
                                </div>
                            </Tooltip>
                        </Col>
                    </Row>
                    {isLoading &&
                    <Row className="my-2 mx-auto w-fc">
                        <Spinner color='beplBlue' animation="border"/>
                    </Row>
                    }
                    {!isLoading &&
                    <Row className="my-2">
                        <div className="main-header text-size-36">{highlightsData.length <= 0 ? 'N/A' : numberFormatter.format(highlightsData.total_cases)}</div>
                    </Row>
                    }
                </Col>
            </Row>
        </Col>
    )   
}

export default HighlightsCard;