import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { slideDrawer } from "../../reducers/UIReducer/UIActions";
import { setDataSuiteAllFilters, setDatasuiteDrawerContents, setSalesByOperator, setSalesByOperatorLoading, setSalesByCategory,
         setSalesByCategoryLoading, setTopOperator, setTrendingCategory, setTrendingSales, setWidgetCatalog, setGrowthOpportunity, 
         setGrowthOpportunityLoading, setCategoriesFilter, setDistributorsFilter, setLocationsFilter, setSegmentsFilter,
         setLeakage, setLeakageLoading, setTargetsByCategory, setTargetsByCategoryLoading, setCompetitiveInsights, setCompetitiveInsightsLoading, 
         setSalesByDistributor, setSalesByDistributorLoading, setOpportunityByDistributorLoading, setOpportunityByDistributor, 
         setOpportunityByStoreLoading, setOpportunityByStore, setNewLocationsLoading, setNewLocations, setLostLocationsLoading, 
         setLostLocations, setSalesVoidOpportunityLoading, setSalesVoidOpportunity, setDataSetsFilter, setDatasuiteDetailsDrawerContents, setBiggestGrowthOpportunity, 
         setDataSetsDetailsFilter, setCategoriesDetailsFilter, setDistributorsDetailsFilter, setLocationsDetailsFilter, setSegmentsDetailsFilter, 
         setSalesVoidOpportunityDetails, setCompetitiveInsightsDetails, setGrowthOpportunityDetails, setLeakageDetails,
         setLostLocationsDetails, setNewLocationsDetails, setOpportunityByDistributorDetails, setOpportunityByStoreDetails,
         setSalesByCategoryDetails, setSalesByDistributorDetails,setSalesByOperatorDetails, setTargetsByCategoryDetails, setItemLevelDescriptionData, setItemLevelDescriptionLoading, setDetailsPageLoading,
         setExcludedSearchDetailsFilter, setIncludedSearchDetailsFilter, setDistributorsExportFilter, setLocationsExportFilter, setCategoriesExportFilter, setSegmentsExportFilter, setDataSetsExportFilter,
         setGrowthOpportunityDetailsLoading, setSalesByOperatorDetailsLoading, setSalesByCategoryDetailsLoading, setLeakageDetailsLoading, setTargetsByCategoryDetailsLoading, setCompetitiveInsightsDetailsLoading, 
         setSalesByDistributorDetailsLoading, setOpportunityByDistributorDetailsLoading, setOpportunityByStoreDetailsLoading, setNewLocationsDetailsLoading, setLostLocationsDetailsLoading, setSalesVoidOpportunityDetailsLoading,
         setCompCategoriesExportFilter, setCompDataSetsExportFilter, setCompLocationsExportFilter, setCompSegmentsExportFilter, setTopProducts, setTopProductsLoading, setTopProductsDetails,
         setTopCategories, setTopCategoriesLoading, setTopCategoriesDetails, setTopProductsDetailsLoading, setTopCategoriesDetailsLoading, setItemMatchData, setItemMatchLoading } from "../../reducers/DatasuiteReducer/DataSuiteActions";
import { setDataSuiteSelectedWidgets } from "../../reducers/UserReducer/UserActions";
import LocalStorage from "../../sharedComponents/LocalStorage/LocalStorage";
import datasuiteApi from "../../api/datasuiteApi";
import widgetApi from "../../api/widgetApi";
import { toast } from 'react-toastify';
import { stringify } from "csv-stringify/sync";
import fileDownload from "js-file-download";
import { setDsFromDate, setDsToDate, setDsDateString, setDsDetailsFromDate, setDsDetailsToDate, setDsDetailsDateString } from "../../reducers/DateReducer/DateActions";
import moment from "moment";
import { showModal } from "../../reducers/ModalsReducer/ModalsActions";
import ItemMatchModal from "../../sharedComponents/ItemMatch/ItemMatchModal";

const useWidgetControls = () => {
  const dispatch = useDispatch();

  const getWidgetCatalog = () => {
    widgetApi.all().then((res) => {
      dispatch(setWidgetCatalog(res.data));
    }).catch(err => {
      console.log(err);
    });
  }

  const getUserWidgetList = () => {
    widgetApi.get().then((res) => {
      dispatch(setDataSuiteSelectedWidgets(res.data));
    }).catch(err => {
      console.log(err);
    });
  }

  const onClickWidgetDrawerOpen = (title, replaceMode = false, replaceIndex = null) => {
    dispatch(setDatasuiteDrawerContents(title, replaceMode, replaceIndex));
    dispatch(slideDrawer.add());
  }

  const onClickWidgetDetailsDrawerOpen = (title, drawerContents) => {
    dispatch(setDatasuiteDetailsDrawerContents(title, drawerContents));
    dispatch(slideDrawer.addDatasuiteDetails());
  }

  const onClickAddWidget = (widget) => {
    dispatch(slideDrawer.subtract());
    widgetApi.subscribe({widgets: [widget.id]}).then((res) => {
      dispatch(setDataSuiteSelectedWidgets(res.data));
      toast.success("Widget added");
    }).catch(err => {
      console.log(err);
    });
  }

  const onClickReplaceWidget = (widget, position) => {
    dispatch(slideDrawer.subtract());
    widgetApi.replace({widgetId: widget.id, index: position}).then((res) => {
      dispatch(setDataSuiteSelectedWidgets(res.data));
      toast.success("Widget replaced");
    }).catch(err => {
      console.log(err);
    });
  }

  const onClickDeleteWidget = (widget) => {
    widgetApi.unsubscribe({widgets: [widget.id]}).then((res) => {
      dispatch(setDataSuiteSelectedWidgets(res.data));
      toast.success("Widget deleted");
    }).catch(err => {
      console.log(err);
    });
  }


  return {
    getWidgetCatalog,
    getUserWidgetList,
    onClickWidgetDrawerOpen,
    onClickWidgetDetailsDrawerOpen,
    onClickAddWidget,
    onClickReplaceWidget,
    onClickDeleteWidget
  };
}

const useDataSuite = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mfrId = LocalStorage.manufacturerData.get().platform_id;
  const fromDate = useSelector(({dates}) => dates.datasuiteFromDate);
  const toDate = useSelector(({dates}) => dates.datasuiteToDate);
  const detailsFromDate = useSelector(({dates}) => dates.datasuiteDetailsFromDate);
  const detailsToDate = useSelector(({dates}) => dates.datasuiteDetailsToDate);
  const allFilters = useSelector(({datasuite}) => datasuite.allFilters);
  const selectedFilters = useSelector(({datasuite}) => datasuite.selectedFilters);
  const selectedDetailsFilters = useSelector(({datasuite}) => datasuite.selectedDetailsFilters);
  const [ filtersDrawerOpen, setFiltersDrawerOpen ] = useState(false);
  const chainData = useSelector(({user}) => user.chainData);
  const emergingData = useSelector(({user}) => user.emergingData);

  const getHighlights = () => {
    const operatorParams = {
      "manufacturerPlatformId": mfrId,
      "chainData": chainData,
      "emergingData": emergingData
    };
    const salesParams = {
      "manufacturerPlatformId": mfrId,
      "startDate": fromDate,
      "endDate": toDate,
      "chainData": chainData,
      "emergingData": emergingData
    };
    const categoriesParams = {
      "manufacturerPlatformId": mfrId,
      "chainData": chainData,
      "emergingData": emergingData
    };
    const growthOpportunityParams = {
      "manufacturerPlatformId": mfrId,
      "chainData": chainData,
      "emergingData": emergingData,
    };
    
    datasuiteApi.highlights.topOperator.post(operatorParams).then((response) => {
      dispatch(setTopOperator(response.data));
    }).catch(err => {
      console.log(err);
    });
    datasuiteApi.highlights.trendingSales.post(salesParams).then((response) => {
      dispatch(setTrendingSales(response.data));
    }).catch(err => {
      console.log(err);
    });
    datasuiteApi.highlights.trendingCategory.post(categoriesParams).then((response) => {
      dispatch(setTrendingCategory(response.data));
    }).catch(err => {
      console.log(err);
    });
    datasuiteApi.highlights.growthOpportunity.post(growthOpportunityParams).then((response) => {
      dispatch(setBiggestGrowthOpportunity(response.data));
    }).catch(err => {
      console.log(err);
    });
  }

  const getTopOperatorReport = (contents) => {
    if (!setSalesByCategoryLoading) {
      getSalesByOperator();
    }
    dispatch(setDatasuiteDetailsDrawerContents('Sales By Operator', contents));
    dispatch(slideDrawer.addDatasuiteDetails());
  }

  const getLostOperatorTrendingReport = (contents) => {
    if (!setLostLocationsLoading) {
      getLostLocations();
    }
    dispatch(setDatasuiteDetailsDrawerContents('Lost Locations', contents));
    dispatch(slideDrawer.addDatasuiteDetails());
  }

  const getOperatorTrendingReport = (contents) => {
    if (!setNewLocationsLoading) {
      getNewLocations();
    }
    dispatch(setDatasuiteDetailsDrawerContents('New Locations', contents));
    dispatch(slideDrawer.addDatasuiteDetails());
  }

  const getCategoryTrendingReport = (contents) => {
    if (!setTargetsByCategoryLoading) {
      getTargetsByCategory();
    }
    dispatch(setDatasuiteDetailsDrawerContents('Sales By Category', contents));
    dispatch(slideDrawer.addDatasuiteDetails());
  }

  const getComplianceLeakageReport = (contents) => {
    if (!setLeakageLoading) {
      getLeakage();
    }
    dispatch(setDatasuiteDetailsDrawerContents('Leakage', contents));
    dispatch(slideDrawer.addDatasuiteDetails());
  }

  const clickMoreFilters = (parentElement) => {
    switch (parentElement) {
      case ('') : {
        if(filtersDrawerOpen) {
          setFiltersDrawerOpen(false);
          if(selectedFilters?.includedSearch?.length > 0 || selectedFilters?.excludedSearch?.length > 0) {
            document.getElementById('filtersDrawer').style.top = '87px';
            document.getElementById('dataSuiteFiltersCard').style.height = '162px';
            document.getElementById('filtersDetailsDrawer').style.top = '130px';
            document.getElementById('filtersDetailsMain').style.marginBottom = '52px';
          } else {
            document.getElementById('filtersDrawer').style.top = '44px';
            document.getElementById('dataSuiteFiltersCard').style.height = '120px';
            document.getElementById('filtersDetailsDrawer').style.top = '88px';
            document.getElementById('filtersDetailsMain').style.marginBottom = '0px';
          }
        } else {
          setFiltersDrawerOpen(true);
          if(selectedFilters?.includedSearch?.length > 0 || selectedFilters?.excludedSearch?.length > 0) {
            document.getElementById('filtersDrawer').style.top = '160px';
            document.getElementById('dataSuiteFiltersCard').style.height = '232px';
            document.getElementById('filtersDetailsDrawer').style.top = '230px';
            document.getElementById('filtersDetailsMain').style.marginBottom = '130px';
          } else {
            document.getElementById('filtersDrawer').style.top = '118px';
            document.getElementById('dataSuiteFiltersCard').style.height = '190px';
            document.getElementById('filtersDetailsDrawer').style.top = '188px';
            document.getElementById('filtersDetailsMain').style.marginBottom = '88px';
          }
        }
        break;
      }
      case ('Details') : {
        if(filtersDrawerOpen) {
          setFiltersDrawerOpen(false);
          document.getElementById('filtersDrawer').style.top = '44px';
          document.getElementById('dataSuiteFiltersCard').style.height = '120px';
          document.getElementById('filtersDetailsDrawer').style.top = '88px';
          document.getElementById('filtersDetailsMain').style.marginBottom = '0px';
        } else {
          setFiltersDrawerOpen(true);
          document.getElementById('filtersDrawer').style.top = '118px';
          document.getElementById('dataSuiteFiltersCard').style.height = '190px';
          document.getElementById('filtersDetailsDrawer').style.top = '188px';
          document.getElementById('filtersDetailsMain').style.marginBottom = '88px';
        }
        break;
      }
      case ('DetailsPage') : {
        if(filtersDrawerOpen) {
          setFiltersDrawerOpen(false);
          if(selectedDetailsFilters?.includedSearch?.length > 0 || selectedDetailsFilters?.excludedSearch?.length > 0) {
            document.getElementById('filtersDetailsPageDrawer').style.top = '200px';
            document.getElementById('filtersDetailsPageMain').style.marginBottom = '0px';
          } else {
            document.getElementById('filtersDetailsPageDrawer').style.top = '200px';
            document.getElementById('filtersDetailsPageMain').style.marginBottom = '0px';
          }
        } else {
          setFiltersDrawerOpen(true);
          if(selectedDetailsFilters?.includedSearch?.length > 0 || selectedDetailsFilters?.excludedSearch?.length > 0) {
            document.getElementById('filtersDetailsPageDrawer').style.top = '310px';
            document.getElementById('filtersDetailsPageMain').style.marginBottom = '72px';
          } else {
            document.getElementById('filtersDetailsPageDrawer').style.top = '285px';
            document.getElementById('filtersDetailsPageMain').style.marginBottom = '72px';
          }
        }
        break;
      }
     default : {
      // do nothing
     } 
    }
  }

  const alignFiltersComponents = () => {
    if(filtersDrawerOpen) {
      if(selectedDetailsFilters?.includedSearch?.length > 0 || selectedDetailsFilters?.excludedSearch?.length > 0) {
        document.getElementById('filtersDetailsPageDrawer').style.top = '310px';
        document.getElementById('filtersDetailsPageMain').style.marginBottom = '72px';
      } else {
        document.getElementById('filtersDetailsPageDrawer').style.top = '285px';
        document.getElementById('filtersDetailsPageMain').style.marginBottom = '72px';
      }
    } else {
      if(selectedDetailsFilters?.includedSearch?.length > 0 || selectedDetailsFilters?.excludedSearch?.length > 0) {
        document.getElementById('filtersDetailsPageDrawer').style.top = '200px';
        document.getElementById('filtersDetailsPageMain').style.marginBottom = '0px';
      } else {
        document.getElementById('filtersDetailsPageDrawer').style.top = '200px';
        document.getElementById('filtersDetailsPageMain').style.marginBottom = '0px';
      }
    }
  }

  const getFilters = () => {
    if(allFilters?.length <= 0) {
        const params = {
        "manufacturerPlatformId": mfrId,
        "chainData": chainData,
        "emergingData": emergingData,
        "categories": selectedFilters?.categories,
        "distributors": selectedFilters?.distributors,
        "locations": selectedFilters?.locations,
        "segments": selectedFilters?.segments
      };
      datasuiteApi.filters.post(params).then((response) => {
        dispatch(setDataSuiteAllFilters(response.data));
      }).catch(err => {
        console.log(err);
      });
    }
  }

  const getSalesByOperator = (details = false) => {
    const params = details ? {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedDetailsFilters?.chainData,
      "emergingData": selectedDetailsFilters?.gpoData,
      "startDate": detailsFromDate,
      "endDate": detailsToDate,
      "categories": selectedDetailsFilters?.categories,
      "distributors": selectedDetailsFilters?.distributors,
      "locations": selectedDetailsFilters?.locations,
      "segments": selectedDetailsFilters?.segments,
      "searchInclude": selectedDetailsFilters?.includedSearch,
      "searchExclude": selectedDetailsFilters?.excludedSearch
    } : {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedFilters?.chainData,
      "emergingData": selectedFilters?.gpoData,
      "startDate": fromDate,
      "endDate": toDate,
      "categories": selectedFilters?.categories,
      "distributors": selectedFilters?.distributors,
      "locations": selectedFilters?.locations,
      "segments": selectedFilters?.segments,
      "searchInclude": selectedFilters?.includedSearch,
      "searchExclude": selectedFilters?.excludedSearch
    };
    !details && dispatch(setSalesByOperatorLoading(true));
    details && dispatch(setDetailsPageLoading(true));
    details && dispatch(setSalesByOperatorDetailsLoading(true));
    datasuiteApi.widgets.salesByOperator.post(params).then((response) => {
      !details && dispatch(setSalesByOperator(response.data));
      dispatch(setSalesByOperatorDetails(response.data));
      !details && dispatch(setSalesByOperatorLoading(false));
      details && dispatch(setDetailsPageLoading(false));
      details && dispatch(setSalesByOperatorDetailsLoading(false));
    }).catch(err => {
      console.log(err);
    });
  }

    const getSalesByOperatorItemLevel = (locationName) => {
    const params = {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedDetailsFilters?.chainData,
      "emergingData": selectedDetailsFilters?.gpoData,
      "startDate": detailsFromDate,
      "endDate": detailsToDate,
      "categories": selectedDetailsFilters?.categories,
      "distributors": selectedDetailsFilters?.distributors,
      "locations": locationName,
      "segments": selectedDetailsFilters?.segments,
      "searchInclude": selectedDetailsFilters?.includedSearch,
      "searchExclude": selectedDetailsFilters?.excludedSearch
    }
    dispatch(setItemLevelDescriptionLoading(true));
    dispatch(setItemLevelDescriptionData([]));
    datasuiteApi.widgets.itemLevelDescriptions.post(params).then((response) => {
      dispatch(setItemLevelDescriptionData(response.data));
      dispatch(setItemLevelDescriptionLoading(false));
    }).catch(err => {
      console.log(err);
    });
  }

  const getSalesByCategory = (details = false) => {
    const params = details ? {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedDetailsFilters?.chainData,
      "emergingData": selectedDetailsFilters?.gpoData,
      "startDate": detailsFromDate,
      "endDate": detailsToDate,
      "categories": selectedDetailsFilters?.categories,
      "distributors": selectedDetailsFilters?.distributors,
      "locations": selectedDetailsFilters?.locations,
      "segments": selectedDetailsFilters?.segments,
      "searchInclude": selectedDetailsFilters?.includedSearch,
      "searchExclude": selectedDetailsFilters?.excludedSearch
    } : {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedFilters?.chainData,
      "emergingData": selectedFilters?.gpoData,
      "startDate": fromDate,
      "endDate": toDate,
      "categories": selectedFilters?.categories,
      "distributors": selectedFilters?.distributors,
      "locations": selectedFilters?.locations,
      "segments": selectedFilters?.segments,
      "searchInclude": selectedFilters?.includedSearch,
      "searchExclude": selectedFilters?.excludedSearch
    };

    !details && dispatch(setSalesByCategoryLoading(true));
    details && dispatch(setDetailsPageLoading(true));
    details && dispatch(setSalesByCategoryDetailsLoading(true));
    datasuiteApi.widgets.salesByCategory.post(params).then((response) => {
      !details && dispatch(setSalesByCategory(response.data));
      dispatch(setSalesByCategoryDetails(response.data));
      !details && dispatch(setSalesByCategoryLoading(false));
      details && dispatch(setDetailsPageLoading(false));
      details && dispatch(setSalesByCategoryDetailsLoading(false));
    }).catch(err => {
      console.log(err);
    });
  }

  const getSalesByCategoryItemLevel = (category) => {
    const params = {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedDetailsFilters?.chainData,
      "emergingData": selectedDetailsFilters?.gpoData,
      "startDate": detailsFromDate,
      "endDate": detailsToDate,
      "categories": category,
      "distributors": selectedDetailsFilters?.distributors,
      "locations": selectedDetailsFilters?.locations,
      "segments": selectedDetailsFilters?.segments,
      "searchInclude": selectedDetailsFilters?.includedSearch,
      "searchExclude": selectedDetailsFilters?.excludedSearch
    }
    dispatch(setItemLevelDescriptionLoading(true));
    dispatch(setItemLevelDescriptionData([]));
    datasuiteApi.widgets.itemLevelDescriptions.post(params).then((response) => {
      dispatch(setItemLevelDescriptionData(response.data));
      dispatch(setItemLevelDescriptionLoading(false));
    }).catch(err => {
      console.log(err);
    });
  }

  const getGrowthOpportunity = (details = false) => {
    const params = details ? {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedDetailsFilters?.chainData,
      "emergingData": selectedDetailsFilters?.gpoData,
      "startDate": detailsFromDate,
      "endDate": detailsToDate,
      "categories": selectedDetailsFilters?.categories,
      "distributors": selectedDetailsFilters?.distributors,
      "locations": selectedDetailsFilters?.locations,
      "segments": selectedDetailsFilters?.segments,
      "searchInclude": selectedDetailsFilters?.includedSearch,
      "searchExclude": selectedDetailsFilters?.excludedSearch
    } : {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedFilters?.chainData,
      "emergingData": selectedFilters?.gpoData,
      "startDate": fromDate,
      "endDate": toDate,
      "categories": selectedFilters?.categories,
      "distributors": selectedFilters?.distributors,
      "locations": selectedFilters?.locations,
      "segments": selectedFilters?.segments,
      "searchInclude": selectedFilters?.includedSearch,
      "searchExclude": selectedFilters?.excludedSearch
    };

    !details && dispatch(setGrowthOpportunityLoading(true));
    details && dispatch(setDetailsPageLoading(true));
    details && dispatch(setGrowthOpportunityDetailsLoading(true));
    datasuiteApi.widgets.growthOpportunity.post(params).then((response) => {
      !details && dispatch(setGrowthOpportunity(response.data));
      dispatch(setGrowthOpportunityDetails(response.data));
      !details && dispatch(setGrowthOpportunityLoading(false));
      details && dispatch(setDetailsPageLoading(false));
      details && dispatch(setGrowthOpportunityDetailsLoading(false));
    }).catch(err => {
      console.log(err);
    });
  }

  const getGrowthOpportunityItemLevel = (operatorName, category) => {
    const params = {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedDetailsFilters?.chainData,
      "emergingData": selectedDetailsFilters?.gpoData,
      "startDate": detailsFromDate,
      "endDate": detailsToDate,
      "categories": category,
      "distributors": selectedDetailsFilters?.distributors,
      "locations": operatorName,
      "segments": selectedDetailsFilters?.segments,
      "searchInclude": selectedDetailsFilters?.includedSearch,
      "searchExclude": selectedDetailsFilters?.excludedSearch
    }
    dispatch(setItemLevelDescriptionLoading(true));
    dispatch(setItemLevelDescriptionData([]));
    datasuiteApi.widgets.itemLevelDescriptions.post(params).then((response) => {
      dispatch(setItemLevelDescriptionData(response.data));
      dispatch(setItemLevelDescriptionLoading(false));
    }).catch(err => {
      console.log(err);
    });
  }

  const getLeakageItemLevel = (operatorName, category) => {
    const params = {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedDetailsFilters?.chainData,
      "emergingData": selectedDetailsFilters?.gpoData,
      "startDate": detailsFromDate,
      "endDate": detailsToDate,
      "categories": category,
      "distributors": selectedDetailsFilters?.distributors,
      "locations": operatorName,
      "segments": selectedDetailsFilters?.segments,
      "searchInclude": selectedDetailsFilters?.includedSearch,
      "searchExclude": selectedDetailsFilters?.excludedSearch
    }
    dispatch(setItemLevelDescriptionLoading(true));
    dispatch(setItemLevelDescriptionData([]));
    datasuiteApi.widgets.itemLevelDescriptions.post(params).then((response) => {
      dispatch(setItemLevelDescriptionData(response.data));
      dispatch(setItemLevelDescriptionLoading(false));
    }).catch(err => {
      console.log(err);
    });
  }

  const getLeakage = (details = false) => {
    const params = details ? {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedDetailsFilters?.chainData,
      "emergingData": selectedDetailsFilters?.gpoData,
      "startDate": detailsFromDate,
      "endDate": detailsToDate,
      "categories": selectedDetailsFilters?.categories,
      "distributors": selectedDetailsFilters?.distributors,
      "locations": selectedDetailsFilters?.locations,
      "segments": selectedDetailsFilters?.segments,
      "searchInclude": selectedDetailsFilters?.includedSearch,
      "searchExclude": selectedDetailsFilters?.excludedSearch
    } : {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedFilters?.chainData,
      "emergingData": selectedFilters?.gpoData,
      "startDate": fromDate,
      "endDate": toDate,
      "categories": selectedFilters?.categories,
      "distributors": selectedFilters?.distributors,
      "locations": selectedFilters?.locations,
      "segments": selectedFilters?.segments,
      "searchInclude": selectedFilters?.includedSearch,
      "searchExclude": selectedFilters?.excludedSearch
    };
    !details && dispatch(setLeakageLoading(true));
    details && dispatch(setDetailsPageLoading(true));
    details && dispatch(setLeakageDetailsLoading(true));
    datasuiteApi.widgets.leakage.post(params).then((response) => {
      !details && dispatch(setLeakage(response.data));
      dispatch(setLeakageDetails(response.data));
      !details && dispatch(setLeakageLoading(false));
      details && dispatch(setDetailsPageLoading(false));
      details && dispatch(setLeakageDetailsLoading(false));
    }).catch(err => {
      console.log(err);
    });
  }

  const getTargetsByCategory = (details = false) => {
    const params = details ? {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedDetailsFilters?.chainData,
      "emergingData": selectedDetailsFilters?.gpoData,
      "startDate": detailsFromDate,
      "endDate": detailsToDate,
      "categories": selectedDetailsFilters?.categories,
      "distributors": selectedDetailsFilters?.distributors,
      "locations": selectedDetailsFilters?.locations,
      "segments": selectedDetailsFilters?.segments,
      "searchInclude": selectedDetailsFilters?.includedSearch,
      "searchExclude": selectedDetailsFilters?.excludedSearch
    } : {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedFilters?.chainData,
      "emergingData": selectedFilters?.gpoData,
      "startDate": fromDate,
      "endDate": toDate,
      "categories": selectedFilters?.categories,
      "distributors": selectedFilters?.distributors,
      "locations": selectedFilters?.locations,
      "segments": selectedFilters?.segments,
      "searchInclude": selectedFilters?.includedSearch,
      "searchExclude": selectedFilters?.excludedSearch
    };
    !details && dispatch(setTargetsByCategoryLoading(true));
    details && dispatch(setDetailsPageLoading(true));
    details && dispatch(setTargetsByCategoryDetailsLoading(true));
    datasuiteApi.widgets.targetsByCategory.post(params).then((response) => {
      !details && dispatch(setTargetsByCategory(response.data));
      dispatch(setTargetsByCategoryDetails(response.data));
      !details && dispatch(setTargetsByCategoryLoading(false));
      details && dispatch(setDetailsPageLoading(false));
      details && dispatch(setTargetsByCategoryDetailsLoading(false));
    }).catch(err => {
      console.log(err);
    });
  }

  const getCompetitiveInsights = (details = false) => {
    !details && dispatch(setCompetitiveInsightsLoading(true));
    details && dispatch(setCompetitiveInsightsDetailsLoading(true));
    if(allFilters?.categories?.length > 0) {
      let categories;
      if (details) {
        categories = (selectedDetailsFilters?.categories?.length > 0) ? selectedDetailsFilters?.categories : allFilters?.categories;
      } else {
        categories = (selectedFilters?.categories?.length > 0) ? selectedFilters?.categories : allFilters?.categories;
      }
      const params = details ? {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedDetailsFilters?.chainData,
      "emergingData": selectedDetailsFilters?.gpoData,
      "startDate": detailsFromDate,
      "endDate": detailsToDate,
      "categories": categories,
      "distributors": selectedDetailsFilters?.distributors,
      "locations": selectedDetailsFilters?.locations,
      "segments": selectedDetailsFilters?.segments,
      "searchInclude": selectedDetailsFilters?.includedSearch,
      "searchExclude": selectedDetailsFilters?.excludedSearch
      } : {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedFilters?.chainData,
      "emergingData": selectedFilters?.gpoData,
      "startDate": fromDate,
      "endDate": toDate,
      "categories": categories,
      "distributors": selectedFilters?.distributors,
      "locations": selectedFilters?.locations,
      "segments": selectedFilters?.segments,
      "searchInclude": selectedFilters?.includedSearch,
      "searchExclude": selectedFilters?.excludedSearch
      };
      datasuiteApi.widgets.competitiveInsights.post(params).then((response) => {
        !details && dispatch(setCompetitiveInsights(response.data));
        dispatch(setCompetitiveInsightsDetails(response.data));
        !details && dispatch(setCompetitiveInsightsLoading(false));
        details && dispatch(setDetailsPageLoading(false));
        details && dispatch(setCompetitiveInsightsDetailsLoading(false));
      }).catch(err => {
        console.log(err);
      });
    }
  }

  const getSalesByDistributor = (details = false) => {
    const params = details ? {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedDetailsFilters?.chainData,
      "emergingData": selectedDetailsFilters?.gpoData,
      "startDate": detailsFromDate,
      "endDate": detailsToDate,
      "categories": selectedDetailsFilters?.categories,
      "distributors": selectedDetailsFilters?.distributors,
      "locations": selectedDetailsFilters?.locations,
      "segments": selectedDetailsFilters?.segments,
      "searchInclude": selectedDetailsFilters?.includedSearch,
      "searchExclude": selectedDetailsFilters?.excludedSearch
    } : {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedFilters?.chainData,
      "emergingData": selectedFilters?.gpoData,
      "startDate": fromDate,
      "endDate": toDate,
      "categories": selectedFilters?.categories,
      "distributors": selectedFilters?.distributors,
      "locations": selectedFilters?.locations,
      "segments": selectedFilters?.segments,
      "searchInclude": selectedFilters?.includedSearch,
      "searchExclude": selectedFilters?.excludedSearch
    };

    !details && dispatch(setSalesByDistributorLoading(true));
    details && dispatch(setDetailsPageLoading(true));
    details && dispatch(setSalesByDistributorDetailsLoading(true));
    datasuiteApi.widgets.salesByDistributor.post(params).then((response) => {
      !details && dispatch(setSalesByDistributor(response.data));
      dispatch(setSalesByDistributorDetails(response.data));
      !details && dispatch(setSalesByDistributorLoading(false));
      details && dispatch(setDetailsPageLoading(false));
      details && dispatch(setSalesByDistributorDetailsLoading(false));
    }).catch(err => {
      console.log(err);
    });
  }

  const getSalesByDistributorItemLevel = (operatorName) => {
    const params = {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedDetailsFilters?.chainData,
      "emergingData": selectedDetailsFilters?.gpoData,
      "startDate": detailsFromDate,
      "endDate": detailsToDate,
      "categories": selectedDetailsFilters?.categories,
      "distributors": operatorName,
      "locations": selectedDetailsFilters?.locations,
      "segments": selectedDetailsFilters?.segments,
      "searchInclude": selectedDetailsFilters?.includedSearch,
      "searchExclude": selectedDetailsFilters?.excludedSearch
    }
    dispatch(setItemLevelDescriptionLoading(true));
    dispatch(setItemLevelDescriptionData([]));
    datasuiteApi.widgets.itemLevelDescriptions.post(params).then((response) => {
      dispatch(setItemLevelDescriptionData(response.data));
      dispatch(setItemLevelDescriptionLoading(false));
    }).catch(err => {
      console.log(err);
    });
  }

  const getOpportunityByDistributor = (details = false) => {
    const params = details ? {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedDetailsFilters?.chainData,
      "emergingData": selectedDetailsFilters?.gpoData,
      "startDate": detailsFromDate,
      "endDate": detailsToDate,
      "categories": selectedDetailsFilters?.categories,
      "distributors": selectedDetailsFilters?.distributors,
      "locations": selectedDetailsFilters?.locations,
      "segments": selectedDetailsFilters?.segments,
      "searchInclude": selectedDetailsFilters?.includedSearch,
      "searchExclude": selectedDetailsFilters?.excludedSearch
    } : {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedFilters?.chainData,
      "emergingData": selectedFilters?.gpoData,
      "startDate": fromDate,
      "endDate": toDate,
      "categories": selectedFilters?.categories,
      "distributors": selectedFilters?.distributors,
      "locations": selectedFilters?.locations,
      "segments": selectedFilters?.segments,
      "searchInclude": selectedFilters?.includedSearch,
      "searchExclude": selectedFilters?.excludedSearch
    };

    !details && dispatch(setOpportunityByDistributorLoading(true));
    details && dispatch(setDetailsPageLoading(true));
    details && dispatch(setOpportunityByDistributorDetailsLoading(true));
    datasuiteApi.widgets.salesByDistributor.post(params).then((response) => {
      !details && dispatch(setOpportunityByDistributor(response.data));
      dispatch(setOpportunityByDistributorDetails(response.data));
      !details && dispatch(setOpportunityByDistributorLoading(false));
      details && dispatch(setDetailsPageLoading(false));
      details && dispatch(setOpportunityByDistributorDetailsLoading(false));
    }).catch(err => {
      console.log(err);
    });
  }

  const getOpportunityByStore = (details = false) => {
    const params = details ? {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedDetailsFilters?.chainData,
      "emergingData": selectedDetailsFilters?.gpoData,
      "startDate": detailsFromDate,
      "endDate": detailsToDate,
      "categories": selectedDetailsFilters?.categories,
      "distributors": selectedDetailsFilters?.distributors,
      "locations": selectedDetailsFilters?.locations,
      "segments": selectedDetailsFilters?.segments,
      "searchInclude": selectedDetailsFilters?.includedSearch,
      "searchExclude": selectedDetailsFilters?.excludedSearch
    } : {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedFilters?.chainData,
      "emergingData": selectedFilters?.gpoData,
      "startDate": fromDate,
      "endDate": toDate,
      "categories": selectedFilters?.categories,
      "distributors": selectedFilters?.distributors,
      "locations": selectedFilters?.locations,
      "segments": selectedFilters?.segments,
      "searchInclude": selectedFilters?.includedSearch,
      "searchExclude": selectedFilters?.excludedSearch
    };

    !details && dispatch(setOpportunityByStoreLoading(true));
    details && dispatch(setDetailsPageLoading(true));
    details && dispatch(setOpportunityByStoreDetailsLoading(true));
    datasuiteApi.widgets.opportunityByStore.post(params).then((response) => {
      !details && dispatch(setOpportunityByStore(response.data));
      dispatch(setOpportunityByStoreDetails(response.data));
      !details && dispatch(setOpportunityByStoreLoading(false));
      details && dispatch(setDetailsPageLoading(false));
      details && dispatch(setOpportunityByStoreDetailsLoading(false));
    }).catch(err => {
      console.log(err);
    });
  }

  const getNewLocations = (details = false) => {
    const params = details ? {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedDetailsFilters?.chainData,
      "emergingData": selectedDetailsFilters?.gpoData,
      "startDate": detailsFromDate,
      "endDate": detailsToDate,
      "categories": selectedDetailsFilters?.categories,
      "distributors": selectedDetailsFilters?.distributors,
      "locations": selectedDetailsFilters?.locations,
      "segments": selectedDetailsFilters?.segments,
      "searchInclude": selectedDetailsFilters?.includedSearch,
      "searchExclude": selectedDetailsFilters?.excludedSearch
    } : {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedFilters?.chainData,
      "emergingData": selectedFilters?.gpoData,
      "startDate": fromDate,
      "endDate": toDate,
      "categories": selectedFilters?.categories,
      "distributors": selectedFilters?.distributors,
      "locations": selectedFilters?.locations,
      "segments": selectedFilters?.segments,
      "searchInclude": selectedFilters?.includedSearch,
      "searchExclude": selectedFilters?.excludedSearch
    };

    !details && dispatch(setNewLocationsLoading(true));
    details && dispatch(setDetailsPageLoading(true));
    details && dispatch(setNewLocationsDetailsLoading(true));
    datasuiteApi.widgets.newLocations.post(params).then((response) => {
      !details && dispatch(setNewLocations(response.data));
      dispatch(setNewLocationsDetails(response.data));
      !details && dispatch(setNewLocationsLoading(false));
      details && dispatch(setDetailsPageLoading(false));
      details && dispatch(setNewLocationsDetailsLoading(false));
    }).catch(err => {
      console.log(err);
    });
  }

  const getNewLocationsItemLevel = (locationName) => {
    const params = {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedDetailsFilters?.chainData,
      "emergingData": selectedDetailsFilters?.gpoData,
      "startDate": detailsFromDate,
      "endDate": detailsToDate,
      "categories": selectedDetailsFilters?.categories,
      "distributors": selectedDetailsFilters?.distributors,
      "locations": locationName,
      "segments": selectedDetailsFilters?.segments,
      "searchInclude": selectedDetailsFilters?.includedSearch,
      "searchExclude": selectedDetailsFilters?.excludedSearch
    }
    dispatch(setItemLevelDescriptionLoading(true));
    dispatch(setItemLevelDescriptionData([]));
    datasuiteApi.widgets.itemLevelDescriptions.post(params).then((response) => {
      dispatch(setItemLevelDescriptionData(response.data));
      dispatch(setItemLevelDescriptionLoading(false));
    }).catch(err => {
      console.log(err);
    });
  }

  const getLostLocations = (details = false) => {
    const params = details ? {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedDetailsFilters?.chainData,
      "emergingData": selectedDetailsFilters?.gpoData,
      "startDate": detailsFromDate,
      "endDate": detailsToDate,
      "categories": selectedDetailsFilters?.categories,
      "distributors": selectedDetailsFilters?.distributors,
      "locations": selectedDetailsFilters?.locations,
      "segments": selectedDetailsFilters?.segments,
      "searchInclude": selectedDetailsFilters?.includedSearch,
      "searchExclude": selectedDetailsFilters?.excludedSearch
    } : {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedFilters?.chainData,
      "emergingData": selectedFilters?.gpoData,
      "startDate": fromDate,
      "endDate": toDate,
      "categories": selectedFilters?.categories,
      "distributors": selectedFilters?.distributors,
      "locations": selectedFilters?.locations,
      "segments": selectedFilters?.segments,
      "searchInclude": selectedFilters?.includedSearch,
      "searchExclude": selectedFilters?.excludedSearch
    };

    !details && dispatch(setLostLocationsLoading(true));
    details && dispatch(setDetailsPageLoading(true));
    details && dispatch(setLostLocationsDetailsLoading(true));
    datasuiteApi.widgets.lostLocations.post(params).then((response) => {
      !details && dispatch(setLostLocations(response.data));
      dispatch(setLostLocationsDetails(response.data));
      !details && dispatch(setLostLocationsLoading(false));
      details && dispatch(setDetailsPageLoading(false));
      details && dispatch(setLostLocationsDetailsLoading(false));
    }).catch(err => {
      console.log(err);
    });
  }

  const getSalesVoidOpportunity = (details = false) => {
    const params = details ? {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedDetailsFilters?.chainData,
      "emergingData": selectedDetailsFilters?.gpoData,
      "startDate": detailsFromDate,
      "endDate": detailsToDate,
      "categories": selectedDetailsFilters?.categories,
      "distributors": selectedDetailsFilters?.distributors,
      "locations": selectedDetailsFilters?.locations,
      "segments": selectedDetailsFilters?.segments,
      "searchInclude": selectedDetailsFilters?.includedSearch,
      "searchExclude": selectedDetailsFilters?.excludedSearch
    } : {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedFilters?.chainData,
      "emergingData": selectedFilters?.gpoData,
      "startDate": fromDate,
      "endDate": toDate,
      "categories": selectedFilters?.categories,
      "distributors": selectedFilters?.distributors,
      "locations": selectedFilters?.locations,
      "segments": selectedFilters?.segments,
      "searchInclude": selectedFilters?.includedSearch,
      "searchExclude": selectedFilters?.excludedSearch
    };

    !details && dispatch(setSalesVoidOpportunityLoading(true));
    details && dispatch(setDetailsPageLoading(true));
    details && dispatch(setSalesVoidOpportunityDetailsLoading(true));
    datasuiteApi.widgets.salesVoid.post(params).then((response) => {
      !details && dispatch(setSalesVoidOpportunity(response.data));
      dispatch(setSalesVoidOpportunityDetails(response.data));
      !details && dispatch(setSalesVoidOpportunityLoading(false));
      details && dispatch(setDetailsPageLoading(false));
      details && dispatch(setSalesVoidOpportunityDetailsLoading(false));
    }).catch(err => {
      console.log(err);
    });
  }

  const getSalesVoidItemLevel = (locationName) => {
    const params = {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedDetailsFilters?.chainData,
      "emergingData": selectedDetailsFilters?.gpoData,
      "startDate": detailsFromDate,
      "endDate": detailsToDate,
      "categories": selectedDetailsFilters?.categories,
      "distributors": selectedDetailsFilters?.distributors,
      "locations": locationName,
      "segments": selectedDetailsFilters?.segments,
      "searchInclude": selectedDetailsFilters?.includedSearch,
      "searchExclude": selectedDetailsFilters?.excludedSearch
    }
    dispatch(setItemLevelDescriptionLoading(true));
    dispatch(setItemLevelDescriptionData([]));
    datasuiteApi.widgets.itemLevelDescriptions.post(params).then((response) => {
      dispatch(setItemLevelDescriptionData(response.data));
      dispatch(setItemLevelDescriptionLoading(false));
    }).catch(err => {
      console.log(err);
    });
  }

  const getTopProducts = (details = false) => {
    const params = details ? {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedDetailsFilters?.chainData,
      "emergingData": selectedDetailsFilters?.gpoData,
      "startDate": detailsFromDate,
      "endDate": detailsToDate,
      "categories": selectedDetailsFilters?.categories,
      "distributors": selectedDetailsFilters?.distributors,
      "locations": selectedDetailsFilters?.locations,
      "segments": selectedDetailsFilters?.segments,
      "searchInclude": selectedDetailsFilters?.includedSearch,
      "searchExclude": selectedDetailsFilters?.excludedSearch
    } : {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedFilters?.chainData,
      "emergingData": selectedFilters?.gpoData,
      "startDate": fromDate,
      "endDate": toDate,
      "categories": selectedFilters?.categories,
      "distributors": selectedFilters?.distributors,
      "locations": selectedFilters?.locations,
      "segments": selectedFilters?.segments,
      "searchInclude": selectedFilters?.includedSearch,
      "searchExclude": selectedFilters?.excludedSearch
    };
    !details && dispatch(setTopProductsLoading(true));
    details && dispatch(setDetailsPageLoading(true));
    details && dispatch(setTopProductsDetailsLoading(true));
    datasuiteApi.widgets.topProducts.post(params).then(response => {
      !details && dispatch(setTopProducts(response.data));
      dispatch(setTopProductsDetails(response.data));
      !details && dispatch(setTopProductsLoading(false));
      details && dispatch(setDetailsPageLoading(false));
      details && dispatch(setTopProductsDetailsLoading(false));
    }).catch(err => {
      console.log(err);
    });
  }

  const getTopCategories = (details = false) => {
    const params = details ? {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedDetailsFilters?.chainData,
      "emergingData": selectedDetailsFilters?.gpoData,
      "startDate": detailsFromDate,
      "endDate": detailsToDate,
      "categories": selectedDetailsFilters?.categories,
      "distributors": selectedDetailsFilters?.distributors,
      "locations": selectedDetailsFilters?.locations,
      "segments": selectedDetailsFilters?.segments,
      "searchInclude": selectedDetailsFilters?.includedSearch,
      "searchExclude": selectedDetailsFilters?.excludedSearch
    } : {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedFilters?.chainData,
      "emergingData": selectedFilters?.gpoData,
      "startDate": fromDate,
      "endDate": toDate,
      "categories": selectedFilters?.categories,
      "distributors": selectedFilters?.distributors,
      "locations": selectedFilters?.locations,
      "segments": selectedFilters?.segments,
      "searchInclude": selectedFilters?.includedSearch,
      "searchExclude": selectedFilters?.excludedSearch
    };
    !details && dispatch(setTopCategoriesLoading(true));
    details && dispatch(setDetailsPageLoading(true));
    details && dispatch(setTopCategoriesDetailsLoading(true));
    datasuiteApi.widgets.topCategories.post(params).then(response => {
      !details && dispatch(setTopCategories(response.data));
      dispatch(setTopCategoriesDetails(response.data));
      !details && dispatch(setTopCategoriesLoading(false));
      details && dispatch(setDetailsPageLoading(false));
      details && dispatch(setTopCategoriesDetailsLoading(false));
    }).catch(err => {
      console.log(err);
    });
  }

  const setSelectedDataSets = (datasets, details = false) => {
    if(datasets?.length > 0) {
      toast.info("Applying filters.", {autoClose: 5000});
    }
    if (datasets) {
      const chainData = datasets.findIndex((ds) => ds.value === "Chain Data") > -1;
      const gpoData = datasets.findIndex((ds) => ds.value === "Emerging Chain Data") > -1;
      dispatch(setDataSetsDetailsFilter(chainData, gpoData));
      !details && dispatch(setDataSetsFilter(chainData, gpoData));
    } else {
      dispatch(setDataSetsDetailsFilter(selectedFilters?.chainData, selectedFilters?.gpoData));
    }
  }

  const setExcludedSearchKeywords = (keyword) => {
    if(keyword?.length > 0) {
      toast.info("Applying filters.", {autoClose: 5000});
    }
    if(keyword) {
      var excludedKeywords = selectedDetailsFilters.excludedSearch; 
      if (excludedKeywords.indexOf(keyword) === -1) {
        excludedKeywords.push(keyword.toLowerCase());
        dispatch(setExcludedSearchDetailsFilter(excludedKeywords));
      }
    } else {
      dispatch(setExcludedSearchDetailsFilter([]));
    } 
  }

  const setIncludedSearchKeywords = (keyword) => {
    if(keyword?.length > 0) {
      toast.info("Applying filters.", {autoClose: 5000});
    }
    if(keyword) {
      var includedKeywords = selectedDetailsFilters.includedSearch; 
      if(includedKeywords.indexOf(keyword) === -1) {
        includedKeywords.push(keyword.toLowerCase());
        dispatch(setIncludedSearchDetailsFilter(includedKeywords));
      }
    } else {
      dispatch(setIncludedSearchDetailsFilter([]));
    } 
  }

  const removeExcludedSearchKeyword = (keyword) => {
    var excludedKeywords = selectedDetailsFilters.excludedSearch; 
    excludedKeywords = excludedKeywords.filter((tag) => tag !== keyword);
    dispatch(setExcludedSearchDetailsFilter(excludedKeywords));
  }

  const removeIncludedSearchKeyword = (keyword) => {
    var includedKeywords = selectedDetailsFilters.includedSearch; 
    includedKeywords = includedKeywords.filter((tag) => tag !== keyword);
    dispatch(setIncludedSearchDetailsFilter(includedKeywords));
  }

  const setSelectedCategories = (categories, details = false) => {
    if(categories?.length > 0) {
      toast.info("Applying filters.", {autoClose: 5000});
    }
    if(categories) {
      const mappedCategories = categories.map(category => category.value);
      dispatch(setCategoriesDetailsFilter(mappedCategories));
      !details && dispatch(setCategoriesFilter(mappedCategories));
    } else {
      dispatch(setCategoriesDetailsFilter([]));
      !details && dispatch(setCategoriesFilter([]));
    } 
  }

  const setSelectedDistributors = (distributors, details = false) => {
    if(distributors?.length > 0) {
      toast.info("Applying filters.", {autoClose: 5000});
    }
    if(distributors) {
      const mappedDistributors = distributors.map(distributor => distributor.value);
      dispatch(setDistributorsDetailsFilter(mappedDistributors));
      !details && dispatch(setDistributorsFilter(mappedDistributors));
    } else {
      dispatch(setDistributorsDetailsFilter([]));
      !details && dispatch(setDistributorsFilter([]));
    }
  }

  const setSelectedLocations = (locations, details = false) => {
    if(locations?.length > 0) {
      toast.info("Applying filters.", {autoClose: 5000});
    }
    if(locations) {
      const mappedLocations = locations.map(location => location.value);
      dispatch(setLocationsDetailsFilter(mappedLocations));
      !details && dispatch(setLocationsFilter(mappedLocations));
    } else {
      dispatch(setLocationsDetailsFilter([]));
      !details && dispatch(setLocationsFilter([]));
    }
  }

  const setSelectedSegments = (segments, details = false) => {
    if(segments?.length > 0) {
      toast.info("Applying filters.", {autoClose: 5000});
    }
    if(segments) {
      const mappedSegments = segments.map(segment => segment.value);
      dispatch(setSegmentsDetailsFilter(mappedSegments));
      !details && dispatch(setSegmentsFilter(mappedSegments));
    } else {
      dispatch(setSegmentsDetailsFilter([]));
      !details && dispatch(setSegmentsFilter([]));
    }
  }

  const changeDataSuiteDates = (fromDate, toDate, details = false) => {
    toast.info("Applying filters.", {autoClose: 15000});
    const dateString = moment(fromDate).format('YYYY-MM-DD') + " - " + moment(toDate).format('YYYY-MM-DD');
    if (details) {
      dispatch(setDsDetailsFromDate(fromDate));
      dispatch(setDsDetailsToDate(toDate));
      dispatch(setDsDetailsDateString(dateString));
    } else {
      dispatch(setDsFromDate(fromDate));
      dispatch(setDsToDate(toDate));
      dispatch(setDsDateString(dateString));
    }
  }

  const filterTargetsByCategoryTable = () => {
    // Declare variables
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("targetsByCategorySearchInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("targetsByCategoryTable");
    tr = table.getElementsByClassName("tr");
  
    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByClassName("td")[0];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }

  const filterTargetsByCategoryDetailsTable = () => {
    // Declare variables
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("targetsByCategoryDetailsSearchInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("targetsByCategoryDetailsTable");
    tr = table.getElementsByClassName("tr");
  
    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByClassName("td")[0];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }

  const onClickViewDetails = (widgetName, filteredItem, filteredItem2) => {
    switch (widgetName) {
      case ("Competitive Insights") : {
        break;
      }
      case ("Growth Opportunities") : {
        dispatch(setLocationsDetailsFilter([filteredItem]));
        break;
      }
      case ("Leakage") : {
        dispatch(setLocationsDetailsFilter([filteredItem]));
        dispatch(setCategoriesDetailsFilter([filteredItem2]));
        break;
      }
      case ("Lost Locations") : {
        dispatch(setLocationsDetailsFilter([filteredItem]));
        break;
      }
      case ("New Locations") : {
        dispatch(setLocationsDetailsFilter([filteredItem]));
        break;
      }
      case ("Opportunities By Distributor") : {
        dispatch(setDistributorsDetailsFilter([filteredItem]));
        break;
      }
      case ("Opportunities By Store") : {
        dispatch(setLocationsDetailsFilter([filteredItem]));
        break;
      }
      case ("Sales By Category") : {
        dispatch(setCategoriesDetailsFilter([filteredItem]));
        break;
      }
      case ("Sales By Distributor") : {
        dispatch(setDistributorsDetailsFilter([filteredItem]));
        break;
      }
      case ("Sales By Operator") : {
        dispatch(setLocationsDetailsFilter([filteredItem]));
        break;
      }
      case ("Sales Void Opportunities") : {
        dispatch(setLocationsDetailsFilter([filteredItem]));
        break;
      }
      case ("Targets By Category") : {
        dispatch(setCategoriesDetailsFilter([filteredItem]));
        break;
      }
      default : {
        // Do nothing
      }
    }
    navigate({pathname: '/salesstream/details', search: "?" + widgetName});
  }

  const onClickViewItemMatches = (location, category) => {
    dispatch(showModal(ItemMatchModal, {category: category, location: location}));
  }

  const getItemMatches = (locationName, category) => {
    const params = {
      "manufacturerPlatformId": mfrId,
      "chainData": selectedDetailsFilters?.chainData,
      "emergingData": selectedDetailsFilters?.gpoData,
      "startDate": detailsFromDate,
      "endDate": detailsToDate,
      "categories": category ? category : selectedDetailsFilters?.categories,
      "distributors": selectedDetailsFilters?.distributors,
      "locations": locationName ? locationName : selectedDetailsFilters?.locations,
      "segments": selectedDetailsFilters?.segments,
      "searchInclude": selectedDetailsFilters?.includedSearch,
      "searchExclude": selectedDetailsFilters?.excludedSearch
    }
    dispatch(setItemMatchLoading(true));
    datasuiteApi.itemMatch.post(params).then((response) => {
      dispatch(setItemMatchData(response.data));
      dispatch(setItemMatchLoading(false));
    }).catch(err => {
      console.log(err);
    });
  }

  const onClickItemMatchesExport = (itemMatches, mfrName, category) => {
    if(itemMatches) {
      let csvData = [];
      // CSV column headers
      csvData.push([
          'MIN',
          'Description',
          'Category',
          'Pack Size',
          'Cases'
      ]);

      itemMatches.forEach(itemMatch => {
          // CSV data rows
          csvData.push([
              itemMatch.match_min,
              itemMatch.match_description,
              category,
              itemMatch.match_pack,
              itemMatch.total_cases
          ]);
      });
      
      // Start the Download
      stringify(csvData, (err, output) => {
          fileDownload(output, `MatchedItems_${mfrName.replaceAll(' ','')}_${category.replaceAll(' ', '')}_${moment().format('DDMMYYYY_hmmss').toString()}.csv`);
      });
    }
  }

  const updateExportFilters = () => {
    dispatch(setDistributorsExportFilter(selectedDetailsFilters.distributors));
    dispatch(setLocationsExportFilter(selectedDetailsFilters.locations));
    dispatch(setCategoriesExportFilter(selectedDetailsFilters.categories));
    dispatch(setSegmentsExportFilter(selectedDetailsFilters.segments));
    dispatch(setDataSetsExportFilter(selectedDetailsFilters.chainData, selectedDetailsFilters.gpoData));
  }

  const updateCompExportFilters = () => {
    dispatch(setCompLocationsExportFilter(selectedDetailsFilters.locations));
    dispatch(setCompCategoriesExportFilter(selectedDetailsFilters.categories));
    dispatch(setCompSegmentsExportFilter(selectedDetailsFilters.segments));
    dispatch(setCompDataSetsExportFilter(selectedDetailsFilters.chainData, selectedDetailsFilters.gpoData));
  }

  const clearDetailsFilter = () => {
    setSelectedDataSets();
    dispatch(setLocationsDetailsFilter([]));
    dispatch(setCategoriesDetailsFilter([]));
    dispatch(setDistributorsDetailsFilter([]));
    dispatch(setSegmentsDetailsFilter([]));
    dispatch(setIncludedSearchDetailsFilter([]));
    dispatch(setExcludedSearchDetailsFilter([]));
  }

  return {
    getHighlights,
    getTopOperatorReport,
    getOperatorTrendingReport,
    getLostOperatorTrendingReport,
    getCategoryTrendingReport,
    getComplianceLeakageReport,
    clickMoreFilters,
    filtersDrawerOpen,
    getFilters,
    getSalesByOperator,
    getSalesByCategory,
    getGrowthOpportunity,
    getLeakage,
    getTargetsByCategory,
    getCompetitiveInsights,
    setSelectedDataSets,
    setSelectedCategories,
    setSelectedDistributors,
    setSelectedLocations,
    setSelectedSegments,
    changeDataSuiteDates,
    filterTargetsByCategoryTable,
    filterTargetsByCategoryDetailsTable,
    getSalesByDistributor,
    getOpportunityByDistributor,
    getOpportunityByStore,
    getNewLocations,
    getLostLocations,
    getSalesVoidOpportunity,
    onClickViewDetails,
    getNewLocationsItemLevel,
    getSalesByOperatorItemLevel,
    getSalesByCategoryItemLevel,
    getGrowthOpportunityItemLevel,
    getSalesByDistributorItemLevel,
    getLeakageItemLevel,
    getSalesVoidItemLevel,
    clearDetailsFilter, 
    setIncludedSearchKeywords,
    setExcludedSearchKeywords,
    removeIncludedSearchKeyword,
    removeExcludedSearchKeyword,
    alignFiltersComponents,
    updateExportFilters,
    getItemMatches,
    onClickViewItemMatches,
    onClickItemMatchesExport,
    updateCompExportFilters,
    getTopProducts,
    getTopCategories
  };
} 

// eslint-disable-next-line
export default { useWidgetControls, useDataSuite };