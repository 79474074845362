import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './Dashboard/Dashboard';
import Logout from './Logout/Logout';
import Help from './Help/Help';
import MarketingCampaigns from './MarketingCampaigns/MarketingCampaigns';
import MarketingCampaignsDetails from './MarketingCampaigns/MarketingCampaignDetails/MarketingCampaignsDetails';
import DataSuite from './DataSuite/DataSuite';
import DataSuiteDetails from './DataSuite/DataSuiteDetails';
import GrowthOpportunities from './DataSuite/GrowthOpportunities';

const AppMainRouter = () => {

  const chainData = useSelector(({user}) => user.chainData);
  const emergingData = useSelector(({user}) => user.emergingData);
  const isArrowstream = localStorage.getItem('branding') === 'ARROWSTREAM';
  const isBEP = localStorage.getItem('branding') === 'BEP';

  const dataSuiteAccess = chainData || emergingData;

  return (
    <Routes>
      <Route exact path='/dashboard' element={<Dashboard />} />
      <Route exact path='/logout' element={<Logout />} />
      <Route exact path='/help' element={<Help />} />
      {isBEP && <Route exact path='/campaignmanagement' element={<MarketingCampaigns />} />}
      {isBEP && <Route path='/campaignmanagement/:id' element={<MarketingCampaignsDetails />} />}
      {(dataSuiteAccess && isArrowstream) && <Route exact path='/salesstream' element={<DataSuite />} />}
      {(dataSuiteAccess && isArrowstream) && <Route exact path='/salesstream/details' element={<DataSuiteDetails />} />}
      {isArrowstream && <Route exact path='/growthopportunities' element={<GrowthOpportunities />} />}
      {isArrowstream && <Route path="*" element={<Navigate to='/salesstream' replace={true} />} />}
      {isBEP && <Route path="*" element={<Navigate to='/dashboard' replace={true} />} />}
    </Routes>
  );
};

export default AppMainRouter;