import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSettings from './useSettings';
import ChangePassword from './ChangePassword/ChangePassword';
import UsersRoles from './UsersRoles/UsersRoles';
import useUserRoles from '../Settings/UsersRoles/useUsersRoles';
import { setAdmin } from '../../reducers/UserReducer/UserActions';
import LocalStorage from '../../sharedComponents/LocalStorage/LocalStorage';
import Profile from './Profile/Profile';


const Settings = () => {
  const { clickChangePassword, clickUsersRoles, clickTabRol, clickTabPassword, tabPassword, tabRol, logOut } = useSettings.useUser();
  const userRolesProps = useUserRoles.useApi();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAdmin = (LocalStorage.userData.get().user.internalAdmin === true);
  const profileLinkSelectedClass = localStorage.getItem('branding') === "ARROWSTREAM" ? 'asProfileLinkSelected' : 'profileLinkSelected';

  const goToChangeManufacturer = () => {
    dispatch(setAdmin(true));
    navigate('/admin-login');
  };

  const clickHelp = () => {
    navigate('/help');
  };

  return (
   <Row className='mx-0 settingsContainer' style={{height: '100vh'}}>
    { isMobile ? 
      <Fragment>
        <Row>
          <Col xs={12} className='py-3 border-bottom' onClick={clickChangePassword}>
            Change Password
          </Col>
          { isAdmin &&
            <Col  xs={12} className='py-3 border-bottom' onClick={goToChangeManufacturer}>
            Change Manufacturer
            </Col>
          }
          <Col xs={12} className='py-3 border-bottom' hidden={true} onClick={clickUsersRoles}>
            Users & Roles
          </Col>
          <Col xs={12} className='py-3 border-bottom' onClick={clickHelp}>
            Help
          </Col>
          <Col xs={12} className='py-3 border-bottom' onClick={logOut}>
            Log Out
          </Col>
        </Row>
      </Fragment>
      : 
      <div className='px-0'>
        <Row className='text-size-20' style={{borderBottom:'4px solid lightgray'}}>
          <Col id={(tabPassword ? profileLinkSelectedClass : '')} className={'w-fc clickable'} onClick={clickTabPassword}>
            <Row className='py-2 px-3'>
              <span>Profile</span>
            </Row>
          </Col>
          <Col id={(tabRol ? profileLinkSelectedClass : '')} className={'w-fc clickable'} onClick={clickTabRol}>
            <Row className='py-2 px-3'>
              <span>Users & Roles</span>
            </Row>
          </Col>
        </Row>
        { tabPassword &&
          <Col className='col-12'>
            <Profile />
            <Row className='px-5 mb-2'>
              <span className='text-size-20 main-header'>Change Password</span>
            </Row>
            <ChangePassword />
          </Col>
        }
        { tabRol &&
          <UsersRoles {...userRolesProps} />
        }
      </div>
    }
    </Row>
  )
}

export default Settings;
