import React from 'react';
import { Col, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { If, Then, Else } from 'react-if';
import { slideDrawer } from '../../reducers/UIReducer/UIActions';
import { FiX } from 'react-icons/fi';
import { isMobile } from 'react-device-detect';
import AgreementStatusIndicator from '../AgreementStatusIndicator/AgreementStatusIndicator';
import useOpportunities from '../../AppMain/Opportunities/useOpportunities';
import { Theme } from '../../config/constants';

const OppDetailsSlideDrawer = (props) => {
  const dispatch = useDispatch();
  const { onClickContactMfrRelations } = useOpportunities.useOpportunities();
  const enabled = useSelector(({ui}) => ui.oppSlideDrawer);
  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: '0'
  });

  const marketingContact = "Caitlyn Donchak";

  var fieldToggles = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];
  var fieldNames = ["Customer ID", "Current Item Description", "Switch Item Description", "Switch Item MIN", "Category", "Manufacturer Growth Specialist", 
                    "Client Manager", "Current Item Packsize", "Current Item Market Index", "Current Item Total Cases", "Compliance Activity", "Opportunity Identified Date", "Status", "Marketing Contact", "Primary Campaign Source", "Additional Information", "Contact MFR Relations", "Opportunity Manager"];

  switch (props?.rowData?.data?.complianceactivity) {
    case ("Manufacturer Portal"):
      fieldToggles = [true, true, true, false, true, true, true, true, true, true, true, true, true, false, false, false, false, false];
      fieldNames = ["Customer ID", "Current Item Description", "Switch Item Description", "Switch Item MIN", "Category", "Manufacturer Growth Specialist", 
                    "Client Manager", "Current Item Packsize", "Current Item Market Index", "Current Item Total Cases", "Compliance Activity", "Opportunity Identified Date", "Status", "", "", "", "", ""];
      break;
    case ("Platform Portal"):
      fieldToggles = [true, true, false, true, true, true, true, false, false, true, true, true, true, false, false, false, false, false, false];
      fieldNames = ["Customer ID", "Opportunity - Item Description", "", "Item MIN", "Category", "Manufacturer Growth Specialist", 
                    "Client Manager", "", "", "Case Quantity", "Compliance Activity", "Opportunity Identified Date", "Status", "", "", "", "", ""];
      break;
    case ("Custom Email Campaign"):
    case ("Marketing Event (summit)"):
    case ("MFR Mailing Sample Campaigns"):
    case ("MFR Spotlight"):
    case ("Newsletter"):
    case ("Social Media"):
    case ("Webinar"):
      fieldToggles = [true, true, false, true, true, true, true, false, false, true, true, true, true, true, true, true, false, false];
      fieldNames = ["Customer ID", "Opportunity - Item Description", "", "Item MIN", "Category", "Manufacturer Growth Specialist", 
                    "Client Manager", "", "", "Case Quantity", "Compliance Activity", "Opportunity Identified Date", "Status", "Marketing Contact", "Primary Campaign Source", "Additional Information", "", ""];
      break;
    case ("Client Manager Request"):
    case ("Business Review"):
    case ("Comparison"):
    case ("Strategic MFR Target"):
      fieldToggles = [true, false, false, false, true, false, false, false, false, false, true, true, true, true, false, false, true, true];
      fieldNames = ["Customer ID", "Opportunity - Item Description", "", "Item MIN", "Category", "Manufacturer Growth Specialist", 
                      "Client Manager", "Case Size", "", "Case Quantity", "Compliance Activity", "Opportunity Identified Date", "Status", "Marketing Contact", "Primary Campaign Source", "Additional Information", "Contact MFR Relations", "Opportunity Manager"];
      break;
    default:
      fieldToggles = [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true];
      fieldNames = ["Customer ID", "Current Item Description", "Switch Item Description", "Switch Item MIN", "Category", "Manufacturer Growth Specialist", 
                    "Client Manager", "Current Item Packsize", "Current Item Market Index", "Current Item Total Cases", "Compliance Activity", "Opportunity Identified Date", "Status", "Marketing Contact", "Primary Campaign Source", "Additional Information", "Contact MFR Relations", "Opportunity Manager"];
      break;
  };

  return (
    <If condition={enabled}>
    <Then>
      <div className="mask" style={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}>
        <div className={(isMobile ? "side-drawer-mobile open" : "side-drawer open")}>
            <Row className="m-3 mt-4">
                <Col>
                    <div className="text-beplBlue main-header text-size-24">{props.title}</div>
                </Col>
                <Col className="w-fc text-size-24">
                    <FiX style={{color: 'gray'}} className="clickable" onClick={() => dispatch(slideDrawer.subtractOpp())}></FiX>
                </Col>
            </Row>
            <Row className="mx-3" style={{overflowY: 'auto', height: '74%'}}>
                <Col className="h-fc" style={{overflowX: 'hidden'}}>
                    <Row>
                        <ul className="mb-0 ml-2" style={{listStyleType: 'none'}}>
                            <li className="border-bottom border-grayLight" style={{"display":(fieldToggles[0] ? "block" : "none")}}>
                              <Row className="ml-2 mb-2">
                                <Col className="col-6" style={{color: Theme().colors.accent}}>
                                  <label>{fieldNames[0]}</label>
                                </Col>
                                <Col className="col-6">
                                  <div>{props.rowData?.data?.id}</div>
                                </Col>
                              </Row>
                            </li>
                            <li className="border-bottom border-grayLight" style={{"display":(fieldToggles[1] ? "block" : "none")}}>
                              <Row className="ml-2 mb-2 mt-2">
                                <Col className="col-6" style={{color: Theme().colors.accent}}>
                                  <label>{fieldNames[1]}</label>
                                </Col>
                                <Col className="col-6">
                                  <div>{props.rowData?.data?.originalProductDescription}</div>
                                </Col>
                              </Row>
                            </li>
                            <li className="border-bottom border-grayLight" style={{"display":(fieldToggles[2] ? "block" : "none")}}>
                              <Row className="ml-2 mb-2 mt-2">
                                <Col className="col-6" style={{color: Theme().colors.accent}}>
                                  <label>{fieldNames[2]}</label>
                                </Col>
                                <Col className="col-6">
                                  <div>{props.rowData?.data?.description}</div>
                                </Col>
                              </Row>
                            </li>
                            <li className="border-bottom border-grayLight" style={{"display":(fieldToggles[3] ? "block" : "none")}}>
                              <Row className="ml-2 mb-2 mt-2">
                                <Col className="col-6" style={{color: Theme().colors.accent}}>
                                  <label>{fieldNames[3]}</label>
                                </Col>
                                <Col className="col-6">
                                  <div>{props.rowData?.data?.switchMin ? props.rowData?.data?.switchMin : 'N/A'}</div>
                                </Col>
                              </Row>
                            </li>
                            <li className="border-bottom border-grayLight" style={{"display":(fieldToggles[4] ? "block" : "none")}}>
                              <Row className="ml-2 mb-2 mt-2">
                                <Col className="col-6" style={{color: Theme().colors.accent}}>
                                  <label>{fieldNames[4]}</label>
                                </Col>
                                <Col className="col-6">
                                  <div>{props.rowData?.data?.category}</div>
                                </Col>
                              </Row>
                            </li>
                            <li className="border-bottom border-grayLight" style={{"display":(fieldToggles[7] ? "block" : "none")}}>
                              <Row className="ml-2 mb-2 mt-2">
                                <Col className="col-6" style={{color: Theme().colors.accent}}>
                                  <label>{fieldNames[7]}</label>
                                </Col>
                                <Col className="col-6">
                                  <div>{props.rowData?.data?.packsize}</div>
                                </Col>
                              </Row>
                            </li>
                            <li className="border-bottom border-grayLight" style={{"display":(fieldToggles[8] ? "block" : "none")}}>
                              <Row className="ml-2 mb-2 mt-2">
                                <Col className="col-6" style={{color: Theme().colors.accent}}>
                                  <label>{fieldNames[8]}</label>
                                </Col>
                                <Col className="col-6">
                                  <div>${props.rowData?.data?.marketIndex}</div>
                                </Col>
                              </Row>
                            </li>
                            <li className="border-bottom border-grayLight" style={{"display":(fieldToggles[9] ? "block" : "none")}}>
                              <Row className="ml-2 mb-2 mt-2">
                                <Col className="col-6" style={{color: Theme().colors.accent}}>
                                  <label>{fieldNames[9]}</label>
                                </Col>
                                <Col className="col-6">
                                  <div>{formatter.format(props.rowData?.data?.totalCases)}</div>
                                </Col>
                              </Row>
                            </li>
                            <li className="border-bottom border-grayLight" style={{"display":(fieldToggles[5] ? "block" : "none")}}>
                              <Row className="ml-2 mb-2 mt-2">
                                <Col className="col-6" style={{color: Theme().colors.accent}}>
                                  <label>{fieldNames[5]}</label>
                                </Col>
                                <Col className="col-6">
                                  <div>{props.rowData?.data?.growthspecialistname}</div>
                                </Col>
                              </Row>
                            </li>
                            <li className="border-bottom border-grayLight" style={{"display":(fieldToggles[6] ? "block" : "none")}}>
                              <Row className="ml-2 mb-2 mt-2">
                                <Col className="col-6" style={{color: Theme().colors.accent}}>
                                  <label>{fieldNames[6]}</label>
                                </Col>
                                <Col className="col-6">
                                  <div>{props.rowData?.data?.clientmanagername}</div>
                                </Col>
                              </Row>
                            </li>
                            <li className="border-bottom border-grayLight" style={{"display":(fieldToggles[13] ? "block" : "none")}}>
                              <Row className="ml-2 mb-2 mt-2">
                                <Col className="col-6" style={{color: Theme().colors.accent}}>
                                  <label>{fieldNames[13]}</label>
                                </Col>
                                <Col className="col-6">
                                  <div>{marketingContact}</div>
                                </Col>
                              </Row>
                            </li>
                            <li className="border-bottom border-grayLight" style={{"display":(fieldToggles[10] ? "block" : "none")}}>
                              <Row className="ml-2 mb-2 mt-2">
                                <Col className="col-6" style={{color: Theme().colors.accent}}>
                                  <label>{fieldNames[10]}</label>
                                </Col>
                                <Col className="col-6">
                                  <div>{props.rowData?.data?.complianceactivity}</div>
                                </Col>
                              </Row>
                            </li>
                            <li className="border-bottom border-grayLight" style={{"display":(fieldToggles[14] ? "block" : "none")}}>
                              <Row className="ml-2 mb-2 mt-2">
                                <Col className="col-6" style={{color: Theme().colors.accent}}>
                                  <label>{fieldNames[14]}</label>
                                </Col>
                                <Col className="col-6">
                                  <div>{props.rowData?.data?.campaignSource}</div>
                                </Col>
                              </Row>
                            </li>
                            <li className="border-bottom border-grayLight" style={{"display":(fieldToggles[11] ? "block" : "none")}}>
                              <Row className="ml-2 mb-2 mt-2">
                                <Col className="col-6" style={{color: Theme().colors.accent}}>
                                  <label>{fieldNames[11]}</label>
                                </Col>
                                <Col className="col-6">
                                  <div>{new Date(props.rowData?.data?.createddate).toLocaleDateString("en-US")}</div>
                                </Col>
                              </Row>
                            </li>
                            <li style={{"display":(fieldToggles[12] ? "block" : "none")}}>
                              <Row className="ml-2 mb-2 mt-2">
                                <Col className="col-6" style={{color: Theme().colors.accent}}>
                                  <label>{fieldNames[12]}</label>
                                </Col>
                                <Col className="col-6">
                                  <div><AgreementStatusIndicator status={props.rowData?.data?.analysisstatus}/></div>
                                </Col>
                              </Row>
                            </li>
                            <li className="border-top border-grayLight" style={{"display":(fieldToggles[15] ? "block" : "none")}}>
                              <Row className="ml-2 mb-2 mt-2">
                                <Col className="col-6" style={{color: Theme().colors.accent}}>
                                  <label>{fieldNames[15]}</label>
                                </Col>
                                <Col className="col-6">
                                  <div>{props.rowData?.data?.additionalInformation}</div>
                                </Col>
                              </Row>
                            </li>
                            <li className="border-top border-grayLight" style={{"display":(fieldToggles[16] ? "block" : "none")}}>
                              <Row className="ml-2 mb-2 mt-2">
                                <Col className="col-12 justify-content-center text-center">
                                  <div className="mb-2 mt-4">Have Questions about this opportunity?</div>
                                  <div className="clickable" style={{color: Theme().colors.accent}} onClick={() => onClickContactMfrRelations(props.rowData.data.customer)}>Contact Manufacturer Relations</div>
                                </Col>
                              </Row>
                            </li>
                        </ul>
                    </Row>
                </Col>
            </Row>
        </div>
      </div>
    </Then>
    <Else>
      <div className="mask hide">
        <div className={(isMobile ? "side-drawer-mobile" : "side-drawer")}>
        </div>
      </div>
    </Else>
  </If>
  );
}

export default OppDetailsSlideDrawer;