export const colors = {
  //Blue
  blue: '#0071CE',
  blueDark: '#23408F',
  blueBasic: '#006DBA',
  blueSec: '#334F9A',
  //White
  white: '#FFFFFF',
  //Black
  black: '#202020',
  shadowLight: '#00001A',
  //Gray
  gray: '#8D8D90',
  grayLight: '#D7D8D9',
  grayLighter: '#EAEAEA',
  grayDark: '#909090',
  grayDarker: '#7F8083',
  grayNew: '#F5F5F5',
  grayText : '#76777B',
  grayBg: '#F0F0F2',
  //Yellow
  yellow: '#FA9016',
  yellowDark: '#F18C18',
  //Purple
  purple: '#766ED7',
  //Green
  green: "#00C283",
  mutedGreen: "#009D9A",
  //Red
  red: '#DC3545',
  redLight: "#FC745A",
  mutedRed: '#CF3F3F',
  //Pink
  magentaLight: "#FAEBF2",
  // Buyers Edge Platform colors
  beplBlue: "#314175",
  beplDarkBlue: "#1D1B4C",
  beplMagenta: "#CF3F7C",
  // ArrowStream colors
  //Blue
  asBlue: '#0F1640',
  brightBlue: '#08A6CC',
  brightBlueLight: '#E6F6FA',
  lightGrayBlue1: '#EFF5F5',
  lightGrayBlue2: '#E2EBEE',
  darkBrightBlue: '#2194AF',
  //Gray
  asGray: '#7A7A7A',
  asDarkGray: '#2E2E2E',
  //Green
  vibrantGreen: '#CBDB2A',
  vibrantGreenLight: '#f4f7d2',
  //Orange
  orange: '#F58220',
  //Red
  brightRed: '#F86464'
};

export const Theme = () => {
  const branding = localStorage.getItem('branding');
  if (branding === "ARROWSTREAM") {
    return {colors: {
      primary: colors.asBlue,
      secondary: colors.brightBlue,
      accent: colors.brightBlue,
      accentLight: colors.brightBlueLight,
      gradientColorOne: colors.asBlue,
      gradientColorTwo: colors.brightBlue,
      primaryName: 'asBlue',
      secondaryName: 'brightBlue',
      accentName: 'brightBlue'
    }}
  } else {
    return {colors: {
      primary: colors.beplDarkBlue,
      secondary: colors.beplBlue,
      accent: colors.beplMagenta,
      accentLight: colors.brightBlueLight,
      gradientColorOne: colors.beplMagenta,
      gradientColorTwo: colors.beplDarkBlue,
      primaryName: 'beplDarkBlue',
      secondaryName: 'beplBlue',
      accentName: 'beplMagenta'
    }}
  }
}

export const selectStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      borderRadius: 2,
      borderColor: state.isFocused ? Theme().colors.accent : '#ced4da',
      ':hover': {
        borderColor: Theme().colors.accent
      },
      boxShadow: 'none'
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 2
    };
  },
  valueContainer: (provided, state) => {
    return {
      ...provided,
      padding: '0.375rem 0.75rem'
    };
  },
  input: (provided, state) => {
    return {
      ...provided,
      margin: 0,
      paddingTop: 0,
      paddingBottom: 0
    };
  },
  singleValue: (provided, state) => {
    return {
      ...provided,
      marginLeft: 0,
      marginRight: 0,
      fontSize: 16
    };
  },
  placeholder: (provided, state) => {
    return {
      ...provided,
      color: '#76777B',
      marginLeft: 0,
      marginRight: 0,
      fontSize: 16
    };
  },
  indicatorSeparator: (provided, state) => {
    return {
      ...provided,
      backgroundColor: 'none'
    };
  },
  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
      color: state.isFocused ? Theme().colors.accent : '#76777B',
      ':hover': {
        color: Theme().colors.accent
      }
    };
  },
};

export const randomColors = [
  '#263238', '#b71c1c', '#78909c', '#ef5350',
  '#424242', '#c2185b', '#212121', '#f06292',
  '#4e342e', '#7b1fa2', '#795548', '#ba68c8',
  '#bf360c', '#5e35b1', '#ff7043', '#7c4dff',
  '#ff9800', '#283593', '#ff6d00', '#ffab00',
  '#304ffe', '#ffca28', '#2962ff', '#ffd600',
  '#2196f3', '#ffea00', '#0091ea', '#03a9f4',
  '#00b8d4', '#00acc1', '#009688', '#00695c',
  '#00c853', '#388e3c', '#2e7d32', '#558b2f',
  '#33691e', '#827717', '#37474f', '#455a64',
  '#546e7a', '#607d8b', '#616161', '#757575',
  '#3e2723', '#5d4037', '#6d4c41', '#8d6e63',
  '#a1887f', '#dd2c00', '#ff3d00', '#d84315',
  '#e64a19', '#f4511e', '#ff5722', '#1b5e20',
  '#43a047', '#4caf50', '#00897b', '#00796b',
  '#004d40', '#006064', '#00838f', '#0097a7',
  '#ff1744', '#00bcd4', '#039be5', '#0288d1',
  '#0277bd', '#01579b', '#1e88e5', '#1976d2',
  '#1565c0', '#0d47a1', '#448aff', '#2979ff',
  '#7986cb', '#ff4081', '#5c6bc0', '#3f51b5',
  '#3949ab', '#536dfe'
];

export const tableColors = [
  Theme().colors.accent, '#303e6f', '#49A4DA', '#7F18BD',
  '#E36060', '#E06017', '#18BD82', '#DF99F0',
  '#197278', '#16BAC5', '#89A1EF', '#1446A0'
];

export const tableauViewNames = {
    insights: 'Distributor Break Down',
    locations: 'New Locations',
    lostLocations: 'Lost Locations',
    competitive: 'Competitive Insights',
    volume: 'Volume Summary',
    topCategories: 'Top Cats/Prods 1',
    volumeTop: 'volumeTop',
    volumeLeft: 'volumeLeft',
    volumeRight: 'volumeRight'
}

export const tableauViewUrls = {
  tableauBaseUrl: 'https://tableau.gpoplatform.com/trusted/',
  insights: '/views/ManufacturerPortalExtractExcludesTportProducts/DistributorBreakDown',
  locations: '/views/ManufacturerPortalExtractExcludesTportProducts/NewLocations',
  lostLocations: '/views/ManufacturerPortalExtractExcludesTportProducts/LostLocations',
  competitive: '/views/ManufacturerPortalExtractExcludesTportProducts/CompetitiveInsights',
  volume: '/views/ManufacturerPortalExtractExcludesTportProducts/VolumeSummary',
  topCategories: '/views/ManufacturerPortalExtractExcludesTportProducts/TopCatsProds1',
  volumeTop: '/views/ManufacturerPortalExtractExcludesTportProducts/VolumeSummaryTop',
  volumeLeft: '/views/ManufacturerPortalExtractExcludesTportProducts/VolumeSummaryLeft',
  volumeRight: '/views/ManufacturerPortalExtractExcludesTportProducts/VolumeSummaryRight'
}

export const opportunityStatuses = ['Active Opportunity', 'Won', 'Won Pending Validation', 'Identified'];

export const opportunitySources = ['Manufacturer Portal', 'Platform Portal', 'Custom Email Campaign', 'Marketing Event (summit)', 'MFR Mailing Sample Campaigns', 'MFR Spotlight',
                                   'Newsletter', 'Social Media', 'Webinar', 'Client Manager Request', 'Business Review', 'Comparison', 'Strategic MFR Target'];

export const portalPageNames = {
  dashboard: "Dashboard",
  competitiveInsights: "Competitive Insights"
}

export const compInsightsCardName = {
  top5Opportunities: 'top5Opportunities',
  categoriesOverview: 'categoriesOverview',
  top5OpportunitiesMobile: 'top5OpportunitiesMobile',
  categoriesOverviewMobile: 'categoriesOverviewMobile'
}

export const uiTabNames = {
  dashboard: 'dashboard',
  campaignManagement: 'campaignManagement',
  opportunityOverview: 'opportunityOverview',
  opportunityStatus: 'opportunityStatus',
  topCategories: 'topCategories',
  topProducts: 'topProducts',
  newLocations: 'newLocations',
  lostLocations: 'lostLocations',
}

export const uiChartNames = {
  totalOpportunitiesTable: 'totalOpportunities',
  competitiveInsightsTable: 'competitiveInsights',
  distributorBreakdownTable: 'distributorBreakdown'
}

export const businessSegments = {
  'CAMP_CONFERENCE':'Camp/Conference',
  'CASINO':'Casino',
  'COMMERCIAL':'Commercial',
  'CORPORATE_FOODSERVICE':'Corporate Food Service',
  'COUNTRY_CLUBS':'Country Clubs',
  'FOOD_RETAILERS':'Food Retailers',
  'GOVERNMENT':'Government',
  'HEALTHCARE':'Healthcare',
  'INSTITUTIONAL':'Institutional',
  'LODGING':'Lodging',
  'RESTAURANT':'Restaurant',
  'RETAIL_STORES':'Retail Stores',
  'SCHOOLS':'Schools',
  'SPORTS_AND_RECREATION':'Sports and Recreation'
}

export const mfrPlatformIdToDmId = {
  'MA-1000003': {'dmId':10956, 'mfrName': 'Unilever Foodsolutions'},
  'MA-1047968': {'dmId':17949, 'mfrName': 'Smuckers Foodservice Inc'},
  'MA-1000001': {'dmId':17949, 'mfrName': 'Smuckers Foodservice Inc'},
  'MA-1000224': {'dmId':17949, 'mfrName': 'Smuckers Foodservice Inc'},
  'MA-1000201': {'dmId':11818, 'mfrName': 'Georgia Pacific North America'},
  'MA-1000247': {'dmId':11818, 'mfrName': 'Georgia Pacific North America'},
  'MA-1000251': {'dmId':18558, 'mfrName': 'Idahoan Foods LLC'},
  'MA-1000100': {'dmId':14721, 'mfrName': 'Keurig Dr Pepper'},
  'MA-1040338': {'dmId':14721, 'mfrName': 'Keurig Dr Pepper'},
  'MA-1000189': {'dmId':19210, 'mfrName': 'Belgioioso Cheese Inc'},
  'MA-1018317': {'dmId':12495, 'mfrName': 'Conagra Foodservice'},
  'MA-1000034': {'dmId':12533, 'mfrName': 'Handgards Inc'},
  'MA-1000176': {'dmId':263858, 'mfrName': 'Malama Eco Products'},
  'MA-1000188': {'dmId':13045, 'mfrName': 'Anchor Packaging Inc'},
  'MA-1006769': {'dmId':14226, 'mfrName': 'Bevolution Group/Lemon-X'},
  'MA-1005572': {'dmId':42162, 'mfrName': 'Beyond Meat Inc'},
  'MA-1000014': {'dmId':17229, 'mfrName': 'Cargill Foodservice'},
  'MA-1000394': {'dmId':13176, 'mfrName': 'Custom Culinary Inc'},
  'MA-1000197': {'dmId':13178, 'mfrName': 'Dart Container Corporation'},
  'MA-1000012': {'dmId':13298, 'mfrName': 'SCA Tissue/Essity Professional'},
  'MA-1000022': {'dmId':12717, 'mfrName': 'Fabri-Kal Corporation'},
  'MA-1047966': {'dmId':11818, 'mfrName': 'Georgia Pacific North America'},
  'MA-1000075': {'dmId':12482, 'mfrName': 'Hormel Foods Corporation'},
  'MA-1000208': {'dmId':11608, 'mfrName': 'Lamb Weston Holdings Inc'},
  'MA-1000016': {'dmId':19628, 'mfrName': 'Pactiv LLC'},
  'MA-1000739': {'dmId':14457, 'mfrName': 'Pan Pacific Plastics Manufacturing'},
  'MA-1024523': {'dmId':12749, 'mfrName': 'Polar Beverages'},
  'MA-1000052': {'dmId':11214, 'mfrName': 'AdvancePierre Food Company'},
  'MA-1000021': {'dmId':14685, 'mfrName': 'Sara Lee Foodservice'},
  'MA-1008314': {'dmId':11906, 'mfrName': 'Original Philadelphia Cheesesteak Co'},
  'MA-1000385': {'dmId':18265, 'mfrName': 'Tyson Fresh Meats IBP'},
  'MA-1000136': {'dmId':13261, 'mfrName': 'Smithfield Farmland Sales Corp'},
  'MA-1000000': {'dmId':12526, 'mfrName': 'Tyson Foods Inc'},
  'MA-1047945': {'dmId':12526, 'mfrName': 'Tyson Foods Inc'},
  'MA-1023709': {'dmId':13849, 'mfrName': 'R3 Redistribution'},
  'MA-1044040': {'dmId':13849, 'mfrName': 'R3 Redistribution'},
  'MA-1000518': {'dmId':13849, 'mfrName': 'R3 Redistribution'},
  'MA-1000068': {'dmId':12517, 'mfrName': 'McCormick & Company Inc'},
}

