export const setUserData = (data) => {
  return {
    type: 'SET_USER_DATA',
    payload: data
  };
};

export const setManufacturerIdData = (data) => {
  return {
    type: 'SET_MANUFACTURER_ID_DATA',
    payload: data
  };
};

export const setFullManufacturersListData = (data) => {
  return {
    type: 'SET_FULL_MANUFACTURERS_LIST_DATA',
    payload: data
  };
};

export const clearUserData = () => {
  return {
    type: 'CLEAR_USER_DATA',
  };
};

export const setUserRolData = (data) => {
  return {
    type: 'SET_USER_ROL_DATA',
    payload: data
  };
};

export const clearSearchLocation = () => {
  return {
    type: 'CLEAR_SEARCH_LOCATION',
  };
};

export const setSearchLocation = (data) => {
  return {
    type: 'SET_SEARCH_LOCATION',
    payload: data
  };
};

export const setLocationChecked = (data) => {
  return {
    type: 'SET_LOCATION_CHECKED',
    payload: data
  };
};

export const setLocationCheckedAll = (data) => {
  return {
    type: 'SET_LOCATION_CHECKED_ALL',
    payload: data
  };
};

export const setActiveDistributor = (distributor) => {
  return {
    type: 'SET_ACTIVE_DISTRIBUTOR',
    payload: distributor
  };
};

export const applyActiveDistributor = () => {
  return {
    type: 'APPLY_ACTIVE_DISTRIBUTOR',
  };
};

export const setDistributorsList = (list) => {
  return {
    type: 'SET_DISTRIBUTORS_LIST',
    payload: list
  };
};

export const setMerchantsAll = (data) => {
  return {
    type: 'SET_MERCHANTS_ALL',
	  payload: data
  };  
};

export const setAdmin = (data) => {
  return {
    type: 'SET_ADMIN',
	  payload: data
  }    
};

export const setDataSuiteSelectedWidgets = (widgets) => {
  return {
    type: 'SET_DATASUITE_SELECTED_WIDGETS',
    payload: widgets
  }
};

export const setDataSets = (chainData, gpoData) => {
  return {
    type: 'SET_DATA_SETS',
    payload: { chainData, gpoData }
  }
};

export const setNotifications = (data) => {
  return {
    type: 'SET_NOTIFICATIONS',
    payload: data
  }
};