import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Group, ScrollArea } from '@mantine/core';
import { LinksGroup } from './NavBarLinksGroup.tsx';
import useTopBar from './useTopBar';
import classes from './TopBar.module.css'

const TopBar = () => {
  const navigate = useNavigate();
  const logoLocation = localStorage.getItem('branding') === 'ARROWSTREAM' ? './arrowstreamLogo.png' : './bepLogo.png'
  const { links, bottomLinks } = useTopBar.useLinks();
  const goToHome = () => {
    if(localStorage.getItem('branding') === 'ARROWSTREAM') {
      localStorage.setItem('branding', 'BEP');
    } else if(localStorage.getItem('branding') === 'BEP') {
      localStorage.setItem('branding', 'ARROWSTREAM');
    } else {
      // DO NOTHING
    }
    navigate('/');
  };

  const toplinksMap = links.map((item) => <LinksGroup {...item} key={item.label} />);
  const bottomLinksMap = bottomLinks.map((item) => <LinksGroup {...item} key={item.label} />);

  return (
    <nav className={classes.navbar}>
      <div className={classes.header}>
        <Group justify="center" style={{width: '100%'}}>
          <img src={logoLocation} alt='Company Icon' className='company-icon' style={{cursor:'pointer'}} onClick={() => goToHome()}/>
        </Group>
      </div>

      <ScrollArea className={classes.links}>
        <div className={classes.linksInner}>{toplinksMap}</div>
      </ScrollArea>

      <div className={classes.linksBottom}>{bottomLinksMap}</div>
    </nav>
  );
};


export default TopBar;