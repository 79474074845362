import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Button } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { BsTrashFill } from 'react-icons/bs';
import useUsersRoles from './useUsersRoles';
import LocalStorage from '../../../sharedComponents/LocalStorage/LocalStorage';
import { Theme } from '../../../config/constants';

const UsersRoles = () => {
  const { userDelete, userInvite } = useUsersRoles.useApi();
  const userRol = useSelector(({user}) => user.userRol);
  const isAdmin = LocalStorage.userData.get().user.internalAdmin === true;

  const userRoleMap = userRol?.map((data, idx) =>{
    return (
        <tr key={`${idx}`}>
            <td>{data?.first_name + " " + data?.last_name}</td>
            <td>{data?.email}</td>
            <td>{data?.title}</td>
            {isAdmin &&
            <td className='clickable'><BsTrashFill color='red' size={16} onClick={()=> userDelete(data)} /></td>
            }
        </tr>
    )
});

  return (
    <Container fluid className='px-0'>
      <Row className='py-2'>
        {isAdmin &&
        <Col  className='w-fc' onClick={()=> userInvite(false)}>
            <Button color={Theme().colors.accentName} outline={true} block className='py-1 button-label'>Invite User</Button>
          </Col>
        }
        </Row>
        { !isMobile &&
            <div style={{overflowY: 'auto', height: '100%'}}>
              <table className="table usersTable border-grayLight" id="usersTable">
                  <thead>
                    <tr>
                        <th scope="col">NAME</th>
                        <th scope="col">EMAIL</th>
                        <th scope="col">ROLE</th>
                        <th scole="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {userRoleMap}
                  </tbody>
              </table>
            </div>
        }
    </Container>
    
  )
}

export default UsersRoles;
