import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Col, Row, Card, Spinner, Button } from 'reactstrap';
import { BsFillBarChartFill } from 'react-icons/bs';
import FiltersCard from './DataSuiteCards/FiltersCard';
import useDataSuiteWidgets from './Widgets/useDataSuiteWidgets';
import { slideDrawer } from '../../reducers/UIReducer/UIActions';
import useDataSuite from './useDataSuite';
import { useState } from 'react';
import { Theme } from '../../config/constants';
import ExportSlideDrawer from '../../sharedComponents/SlideDrawer/ExportSlideDrawer';
import getWelcomeName from '../../sharedComponents/WelcomeName/getWelcomeName';

const DataSuiteDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { getCompetitiveInsights, getGrowthOpportunity, getLeakage, getLostLocations, getNewLocations, getOpportunityByDistributor,
          getOpportunityByStore, getSalesByCategory, getSalesByDistributor, getSalesByOperator, getSalesVoidOpportunity,
          getTargetsByCategory, getTopProducts, getTopCategories, clearDetailsFilter, updateExportFilters, updateCompExportFilters } = useDataSuite.useDataSuite();
  const selectedDetailsFilters = useSelector(({datasuite}) => datasuite.selectedDetailsFilters);
  const widgetDetailsData = useSelector(({datasuite}) => datasuite.widgetDetailsData);
  const detailsPageLoading =  useSelector(({datasuite}) => datasuite.isLoading.detailsPageLoading);
  const [detailsContent, setDetailsContent] = useState(<div>No data to display.</div>);
  const competitiveInsightsWidget = useDataSuiteWidgets.useCompetitiveInsightsWidget();
  const growthOpportunitiesWidget = useDataSuiteWidgets.useGrowthOpportunityWidget();
  const leakageWidget = useDataSuiteWidgets.useLeakageWidget();
  const lostLocationsWidget = useDataSuiteWidgets.useLostLocationsWidget();
  const newLocationsWidget = useDataSuiteWidgets.useNewLocationsWidget();
  const opportunitesByDistributorWidget = useDataSuiteWidgets.useOpportunityByDistributorWidget();
  const opportunitiesByStoreWidget = useDataSuiteWidgets.useOpportunityByStoreWidget();
  const salesByCategoryWidget = useDataSuiteWidgets.useSalesByCategoryWidget();
  const salesByDistributorWidget = useDataSuiteWidgets.useSalesByDistributorWidget();
  const salesByOperatorWidget = useDataSuiteWidgets.useSalesByOperatorWidget();
  const salesVoidOpportunityWidget = useDataSuiteWidgets.useSalesVoidOpportunityWidget();
  const targetsByCategoryWidget = useDataSuiteWidgets.useTargetsByCategoryWidget();
  const topProductsWidget = useDataSuiteWidgets.useTopProductsWidget();
  const topCategoriesWidget = useDataSuiteWidgets.useTopCategoriesWidget();
  var widgetName = location.search.replaceAll('?', '').replaceAll('%20', ' ');
  const {onClickWidgetDetailsDrawerOpen} = useDataSuite.useWidgetControls();
  const widgetDetailsContent = useDataSuiteWidgets.detailsContent;
  var mfrName = getWelcomeName() || 'Admin';

  const closeSlideDrawer = () => {
    dispatch(slideDrawer.subtractDatasuiteDetails());
  }

  // eslint-disable-next-line
  useEffect(() => {closeSlideDrawer(); getWidgetContent(); window.scrollTo({ top: 0, behavior: 'smooth' });}, []);

  const handleClearFilters = () => {
    clearDetailsFilter();
  };

  const onClickWidgetName = () => {
    navigate(-1);
    handleClearFilters();
    onClickWidgetDetailsDrawerOpen(widgetName, widgetDetailsContent);
  };

  const getWidgetContent = () => {
      switch (widgetName) {
      case ("Competitive Insights") : {
        setDetailsContent(competitiveInsightsWidget.detailsContent());
        break;
      }
      case ("Growth Opportunities") : {
        setDetailsContent(growthOpportunitiesWidget.detailsContent());
        break;
      }
      case ("Leakage") : {
        setDetailsContent(leakageWidget.detailsContent());
        break;
      }
      case ("Lost Locations") : {
        setDetailsContent(lostLocationsWidget.detailsContent());
        break;
      }
      case ("New Locations") : {
        setDetailsContent(newLocationsWidget.detailsContent());
        break;
      }
      case ("Opportunities By Distributor") : {
        setDetailsContent(opportunitesByDistributorWidget.detailsContent());
        break;
      }
      case ("Opportunities By Store") : {
        setDetailsContent(opportunitiesByStoreWidget.detailsContent());
        break;
      }
      case ("Sales By Category") : {
        setDetailsContent(salesByCategoryWidget.detailsContent());
        break;
      }
      case ("Sales By Distributor") : {
        setDetailsContent(salesByDistributorWidget.detailsContent());
        break;
      }
      case ("Sales By Operator") : {
        setDetailsContent(salesByOperatorWidget.detailsContent());
        break;
      }
      case ("Sales Void Opportunities") : {
        setDetailsContent(salesVoidOpportunityWidget.detailsContent());
        break;
      }
      case ("Targets By Category") : {
        setDetailsContent(targetsByCategoryWidget.detailsContent);
        break;
      }
      case ("Top Products") : {
        setDetailsContent(topProductsWidget.detailsContent());
        break;
      }
      case ("Top Categories") : {
        setDetailsContent(topCategoriesWidget.detailsContent());
        break;
      }
      default : {
        // Do nothing
      }
    }
  };

  const updateWidgetContent = () => {
    switch (widgetName) {
      case ("Competitive Insights") : {
        getCompetitiveInsights(true);
        break;
      }
      case ("Growth Opportunities") : {
        getGrowthOpportunity(true);
        break;
      }
      case ("Leakage") : {
        getLeakage(true);
        break;
      }
      case ("Lost Locations") : {
        getLostLocations(true);
        break;
      }
      case ("New Locations") : {
        getNewLocations(true);
        break;
      }
      case ("Opportunities By Distributor") : {
        getOpportunityByDistributor(true);
        break;
      }
      case ("Opportunities By Store") : {
        getOpportunityByStore(true);
        break;
      }
      case ("Sales By Category") : {
        getSalesByCategory(true);
        break;
      }
      case ("Sales By Distributor") : {
        getSalesByDistributor(true);
        break;
      }
      case ("Sales By Operator") : {
        getSalesByOperator(true);
        break;
      }
      case ("Sales Void Opportunities") : {
        getSalesVoidOpportunity(true);
        break;
      }
      case ("Targets By Category") : {
        getTargetsByCategory(true);
        break;
      }
      case ("Top Products") : {
        getTopProducts(true);
        break;
      }
      case ("Top Categories") : {
        getTopCategories(true);
        break;
      }
      default : {
        // Do nothing
      }
    }
  };
  
  // eslint-disable-next-line
  useEffect(() => {updateWidgetContent(); updateExportFilters(); updateCompExportFilters();}, [selectedDetailsFilters]);

  // eslint-disable-next-line
  useEffect(getWidgetContent, [widgetDetailsData]);

  return (
    <Col className='px-3 py-3'>
      <Col className='d-flex justify-content-between align-items-end' >
        <div className='main-header text-size-32 ' style={{fontWeight: '700', letterSpacing:'.0025em', lineHeight: '38px'}}>
          <BsFillBarChartFill
              className='me-1'
              style={{
                verticalAlign: 'middle',
                marginBottom: '8px',
                fill: 'url(#icon-gradient)' 
              }}
            />
          Data Stream 
        </div>    
        <svg width= '26px' height='26px' style={{position: 'absolute'}}>
          <linearGradient id="icon-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop stopColor={Theme().colors.gradientColorTwo} offset="0%" />
            <stop stopColor={Theme().colors.gradientColorOne} offset="100%" />
          </linearGradient>
        </svg>
        <div className='main-header text-size-20 align-self-end' style={{lineHeight:'23px', fontWeight:'700',letterSpacing:'.0025em' }}>{mfrName}</div>
      </Col>
      <div className='main-header text-size-20 align-self-end' style={{lineHeight:'23px', fontWeight:'400',letterSpacing:'.0025em' }}>{widgetName} Details</div>
      <hr className='line' style={{border: '1px solid grey'}}></hr>
      <Row className='mx-0 flex align-items-center'>
            <Col className="px-1 pb-2 w-fc">
                <div className="main-header text-size-16 clickable" style={{color: Theme().colors.accent}} onClick={() => onClickWidgetName()}>{widgetName}</div>
            </Col>
            <Col className="px-1 pb-2 w-fc">
                <div className="main-header text-size-20">&#62;</div>
            </Col>
            <Col className="px-1 pb-2">
                <div className="main-header text-size-16">View Details</div>
            </Col>
            <Col className='w-fc pb-2 ml-auto'>
                <Button 
                    block 
                    className='py-1 button-label' 
                    size='sm' 
                    style={{width: '80px'}}
                    color={Theme().colors.accentName}
                    onClick={() => dispatch(slideDrawer.addExport())}>Export
                </Button>
            </Col>
        </Row>
      <Row className='px-0'>
          <FiltersCard parentElement="DetailsPage" widgetName={widgetName}/>
      </Row>
      <Row className='mx-0' style={{height: '76vh'}}>
        <Card>
          {detailsPageLoading && 
          <div className='h-100' style={{position: 'absolute', width: '99%', zIndex: '5', background:'white'}}>
            <Spinner animation="border" color='beplBlue' style={{marginLeft: '50%', marginTop:'38vh'}}/>
          </div>
          }
          <div className={(selectedDetailsFilters?.includedSearch.length > 0 || selectedDetailsFilters?.excludedSearch.length > 0) ? 'searchFilterApplied h-100' : 'h-100'}>
            {detailsContent}
          </div>
        </Card>
      </Row>
      <ExportSlideDrawer title={widgetName} className="exportSlideDrawer" drawerContents={""}/>
    </Col>
  )
}

export default DataSuiteDetails;
