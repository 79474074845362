import LocalStorage from '../LocalStorage/LocalStorage';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { setAdmin, setFullManufacturersListData, setUserData, setDataSets } from '../../reducers/UserReducer/UserActions';
import { mfrPlatformIdToDmId } from '../../config/constants';
import userApi from '../../api/userApi';
//import notificationsApi from '../../api/notificationsApi';

const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login = (data) => {
    const {manufacturer, manufacturers, ...userData} = data;
    LocalStorage.resetKey.set(data.user.reset_password_token);
    LocalStorage.userData.set(userData);
    LocalStorage.authKey.set(data.user.token);
    localStorage.setItem('branding', 'BEP');
    if(manufacturers) {
      LocalStorage.allManufacturerData.set(manufacturers);
      dispatch(setFullManufacturersListData(manufacturers));
    }
    if (manufacturer) {
      if (mfrPlatformIdToDmId[manufacturer.platform_id]) {
        const dmIdData = {
          ...manufacturer,
          data_manager_id: mfrPlatformIdToDmId[manufacturer.platform_id].dmId
        }
        LocalStorage.manufacturerData.set(dmIdData);
      } else {
        LocalStorage.manufacturerData.set(manufacturer);
      }
    }
    if (data.user.internalAdmin === true) {
      dispatch(setAdmin(true));
    }
    //getNotifications();
    dispatch(setDataSets(data.user.chainData, data.user.emergingData));
    dispatch(setUserData(data));
  };

  const manufacturerLogin = (data) => {
    if (mfrPlatformIdToDmId[data.platform_id]) {
      const dmIdData = {
        ...data,
        data_manager_id: mfrPlatformIdToDmId[data.platform_id].dmId
      }
      LocalStorage.manufacturerData.set(dmIdData);
    } else {
      LocalStorage.manufacturerData.set(data);
    }
  };

  const adminUserLogin = (userId) => {
    const params = {
      "id" : userId
    };
    userApi.adminUserLogin.post(params).then((data) => {
      login(data.data);
      localStorage.removeItem('allManufacturerData');
      dispatch(setAdmin(false));
      navigate('/dashboard');
    });
  }

  // const getNotifications = () => {
  //   notificationsApi.all.get().then((response) => {
  //     dispatch(setNotifications(response.data));
  //   });
  // }

  const authenticateTableau = (data) => {
    LocalStorage.tableauTicket.set(data);
  };

  return {
    login,
    authenticateTableau,
    manufacturerLogin,
    adminUserLogin
  };
};

export default useLogin;
