import React, { useEffect } from 'react';
import { Row, Col, Card } from 'reactstrap';
import useDashboard from './useDashboard';
import { Theme } from '../../config/constants';
import moment from 'moment';
import DateRangePicker from '../../sharedComponents/DateRangePicker/DateRangePicker';
import { useSelector } from 'react-redux';
import HighlightsCard from './DashboardCards/HighlightsCard';
import ProductsLocationsCard from './DashboardCards/ProductsLocationsCard';
import DashboardChartsCard from './DashboardCards/DashboardChartsCard';
import BEPDistributorListCard from './DashboardCards/BEPDistributorListCard';
import SlideDrawer from '../../sharedComponents/SlideDrawer/SlideDrawer';
import getWelcomeName from '../../sharedComponents/WelcomeName/getWelcomeName';
import { BsFillGridFill } from 'react-icons/bs';

const Dashboard = () => {
  const {
    fromDate, toDate, onApplySearch, onChangeDashboardDates
  } = useDashboard.useDates();
  const { setDashboardTab } = useDashboard.useDashboard();
  const minDate = useSelector(({dates}) => dates.dashMinDate);
  const dashDateString = useSelector(({dates}) => dates.dashDateString);
  const slideDrawerContents = useSelector(({dashboard}) => dashboard.slideDrawer);
  var mfrName = getWelcomeName() || 'Admin';

  const initializePage = () => {
    setDashboardTab();
  };

  useEffect(initializePage
    //  eslint-disable-next-line  
  , []);

  //  eslint-disable-next-line  
  useEffect(() => onApplySearch(), [dashDateString]);

  return (
    <div className='bg-transparent pt-3 px-3 summaryContainer'>
      <Col className='ps-0 d-flex justify-content-between align-items-end' >
        <div className='main-header text-size-32 ' style={{fontWeight: '700', letterSpacing:'.0025em', lineHeight: '38px'}}>
          <BsFillGridFill
              className='me-1'
              style={{
                verticalAlign: 'middle',
                marginBottom: '7px',      
                fill: 'url(#icon-gradient)' 
              }}
            />
          Dashboard 
          </div>    
        <svg width= '26px' height='26px'>
        <linearGradient id="icon-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop stopColor={Theme().colors.gradientColorTwo} offset="0%" />
          <stop stopColor={Theme().colors.gradientColorOne} offset="100%" />
        </linearGradient>
      </svg>
      <div className='main-header text-size-20 align-self-end' style={{lineHeight:'23px', fontWeight:'700',letterSpacing:'.0025em' }}>Welcome, {mfrName}</div>
    </Col>
    <hr className='line' style={{border: '1px solid grey'}}></hr>
      <Row className='label-text text-size-14 d-flex align-items-center mx-auto pb-2'>
        <Col className='ps-0'>
          <div className='main-header text-size-20' style={{lineHeight:'23px', fontWeight:'700',letterSpacing:'.0025em' }}>Today's Highlights</div>
        </Col>
        <Col className='col-auto ps-0 pe-0'>
          <Row>
            <div className='text-size-12'>Select Date Range</div> 
          </Row>
          <Row>
            <DateRangePicker 
                id='csvDateSearch' 
                defaultStartDate={moment(fromDate).format('MM/DD/YYYY')} 
                defaultEndDate={moment(toDate).format('MM/DD/YYYY')} 
                minDate={moment(minDate).format('MM/DD/YYYY')} 
                maxDate={moment().format('MM/DD/YYYY')} 
                callback={(start, end) => onChangeDashboardDates(start, end)}
                ranges={{
                  'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                  'Last 2 Months': [moment().subtract(2, 'month'), moment()],
                  'Last Year': [moment().subtract(1, 'year'), moment()]
                  }
                }
            />
          </Row>
        </Col>
      </Row>
      <Row xs={1} md={1} lg={1} xl={1} className='label-text text-size-14 d-flex align-items-center card-row g-2'>
        <Card className='dashboard-card px-3' style={{height: '170px'}}>
          <HighlightsCard />
        </Card>
        <Card className='dashboard-card px-3'>
          <ProductsLocationsCard />
        </Card>
        <Card className='dashboard-card px-3' style={{minHeight: '750px', height: '100%'}}>
          <DashboardChartsCard />
        </Card>
        <Card className='dashboard-card px-3' style={{minHeight: '450px'}}>
          <BEPDistributorListCard />
        </Card>
      </Row>
      <Row>
        <Col className=' w-fc ms-auto me-0'>
          <div className='text-beplBlue' style={{'display': (mfrName ? 'block' : 'none')}}>*All data is for {mfrName}</div>
        </Col>
      </Row>
      <SlideDrawer title={slideDrawerContents.title} drawerContents={slideDrawerContents.contents}/>
    </div>
  );
}

export default Dashboard;