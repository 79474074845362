import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Card } from 'reactstrap';
import { dataLoading } from '../../reducers/OpportunitiesReducer/OpportunitiesActions';
import useMarketingCampaigns from './useMarketingCampaigns';
import OpportunitiesTables from '../Opportunities/OpportunitiesTables';
import useOpportunities from '../Opportunities/useOpportunities';
import MarketingCampaignsOverview from './MarketingCampaignsOverview';
import MarketingCampaignsStatus from './MarketingCampaignsStatus';
import OppDetailsSlideDrawer from '../../sharedComponents/SlideDrawer/OppDetailsSlideDrawer';
import { Theme } from '../../config/constants';

const MarketingCampaignsMobile = () => {
    const dispatch = useDispatch;
    dispatch(dataLoading);
    const { getMarketingCampaigns, tabOverview, tabStatus, handleClickOverviewNav, handleClickStatusNav, moveNavBar } = useMarketingCampaigns.useMarketingCampaigns();
    const isLoading = useSelector(({marketing}) => marketing.isLoading);
    const oppRowData = useSelector(({ui}) => ui.oppSlideDrawerData);
    const { getManufacturerAgreements, setAllFilters } = useOpportunities.useOpportunities();
    const campaignManagementNavigationBarOptions = useSelector(({ui}) => ui.campaignManagementNavigationBarOptions);
    const w = campaignManagementNavigationBarOptions.width;
    const l = campaignManagementNavigationBarOptions.left;
    const cmLinkSelectedClass = localStorage.getItem('branding') === "ARROWSTREAM" ? 'asCmLinkSelected' : 'cmLinkSelected';

    const initializePage = () => {
        getMarketingCampaigns();
        getManufacturerAgreements([]);
        setAllFilters();
    };

    // eslint-disable-next-line
    useEffect(initializePage, []);

    // eslint-disable-next-line
    useEffect(moveNavBar, [tabOverview, tabStatus]);

    if (isLoading) {
        return (
            <Container fluid className='pt-3 marketingCampaignsContainer'>
                <OpportunitiesTables />
                <Row className="px-3">
                    <Card className="dashboard-card py-3" style={{height: '54vh'}}>
                        <Row className='px-3 pb-2'>
                            <Col className='w-fc p-0'>
                                <div style={{height: '25px', width: '6px', backgroundColor: Theme().colors.accent}}></div>
                            </Col>
                        </Row>
                        <Row className='mx-0 mb-2' style={{height: '50px', borderBottom: '2px solid lightgray'}}>
                            <Container id='subheader-container' className='h-100 align-items-left' fluid>
                                <Row className='text-black text-size-14 clickable align-items-left h-100'>
                                    <Col className={'campaignManagementLink w-fc position-relative h-100 pb-1 ' + (tabOverview ? 'text-black' : 'text-gray')} id={tabOverview ? cmLinkSelectedClass : ''} onClick={() => handleClickOverviewNav()}>
                                        <span className='campaign-management-nav-label'>CAMPAIGN OVERVIEW</span>
                                    </Col>
                                    <Col className={'campaignManagementLink w-fc position-relative h-100 pb-1 ' + (tabStatus ? 'text-black' : 'text-gray')} id={tabStatus ? cmLinkSelectedClass : ''} onClick={() => handleClickStatusNav()}>
                                        <span className='campaign-management-nav-label'>OPPORTUNITY STATUS</span>
                                    </Col>
                                </Row>
                            </Container>
                            <div id='campaignManagementNavigationBar' style={{width: `${w}px`, left: `${l}px`}}></div>
                        </Row>
                        { tabOverview &&
                            <MarketingCampaignsOverview />
                        }
                        { tabStatus &&
                            <MarketingCampaignsStatus />
                        }
                    </Card>
                </Row>
                <OppDetailsSlideDrawer title={"Details"} rowData={oppRowData}/>
            </Container>
        );
    }
    return (
        <Container fluid className='pt-3 marketingCampaignsContainer'>
            <OpportunitiesTables />
            <Row className="px-3">
                <Card className="dashboard-card py-3" style={{height: '54vh'}}>
                    <Row className='px-3 pb-2'>
                        <Col className='w-fc p-0'>
                            <div style={{height: '25px', width: '6px', backgroundColor: Theme().colors.accent}}></div>
                        </Col>
                    </Row>
                    <Row className='mx-0 mb-2' style={{height: '50px', borderBottom: '2px solid lightgray'}}>
                        <Container id='subheader-container' className='h-100 align-items-left' fluid>
                            <Row className='text-black text-size-14 clickable align-items-left h-100'>
                                <Col className={'campaignManagementLink w-fc position-relative h-100 pb-1 ' + (tabOverview ? 'text-black' : 'text-gray')} id={tabOverview ? cmLinkSelectedClass : ''} onClick={() => handleClickOverviewNav()}>
                                    <span className='campaign-management-nav-label'>CAMPAIGN OVERVIEW</span>
                                </Col>
                                <Col className={'campaignManagementLink w-fc position-relative h-100 pb-1 ' + (tabStatus ? 'text-black' : 'text-gray')} id={tabStatus ? cmLinkSelectedClass : ''} onClick={() => handleClickStatusNav()}>
                                    <span className='campaign-management-nav-label'>OPPORTUNITY STATUS</span>
                                </Col>
                            </Row>
                        </Container>
                        <div id='campaignManagementNavigationBar' style={{width: `${w}px`, left: `${l-41-75}px`}}></div>
                    </Row>
                    {tabOverview &&
                        <MarketingCampaignsOverview />
                    }
                    { tabStatus &&
                        <MarketingCampaignsStatus />
                    }
                </Card>
            </Row>
            <OppDetailsSlideDrawer title={"Details"} rowData={oppRowData}/>
        </Container>
    );
}

export default MarketingCampaignsMobile;